import {html, css, LitElement} from 'lit';
import {customElement} from 'lit/decorators.js';
import {connect} from 'pwa-helpers';
import store from '../../store';
import style from '../../../../scss/audioPlayer.scss';
import playAudio from '../../icons/playAudio';
import pauseAudio from '../../icons/pauseAudio';
import muteAudio from '../../icons/muteAudio';
import volume from '../../icons/volume';

@customElement('arc-audio')
class AudioPlayer extends connect(store)(LitElement) {
	static styles = [style];

	static properties = {
		file: {type: Object},
		alt: {type: String}
	};

	constructor() {
		super();
		this.file = null;
		this.alt = '';
		store.subscribe(() => this.requestUpdate());
	}

	togglePlay() {
		const audio = this.shadowRoot.getElementById('audio');
		if (audio.paused) {
			audio.play();
			this.isPlaying = true;
		} else {
			audio.pause();
			this.isPlaying = false;
		}
		this.requestUpdate();
	}

	loadMetadata() {
		const audio = this.shadowRoot.getElementById('audio');
		const duration = Math.floor(audio.duration);
		const durationFormatted = `${Math.floor(duration / 60)}:${('0' + (duration % 60)).slice(-2)}`;
		this.shadowRoot.querySelector('.duration').textContent = durationFormatted;
	}

	updateTime() {
		const audio = this.shadowRoot.getElementById('audio');
		const currentTime = Math.floor(audio.currentTime);
		const currentTimeFormatted = `${Math.floor(currentTime / 60)}:${(
			'0' +
			(currentTime % 60)
		).slice(-2)}`;
		this.shadowRoot.querySelector('.currentTime').textContent = currentTimeFormatted;
		this.shadowRoot.querySelector('.seekBar').value = (currentTime / audio.duration) * 100;
	}

	toggleMute() {
		const audio = this.shadowRoot.getElementById('audio');
		audio.muted = !audio.muted;
		this.isMuted = audio.muted; // Update mute state
		this.requestUpdate(); // Request update to re-render with new state
	}

	render() {
		if (!this.file || !this.file.url || !['mp3', 'wav', 'ogg'].includes(this.file.ext)) return null;

		return html`
			<div class="audioPreview">
				<div class="customAudioPlayer">
					<button @click="${this.togglePlay}" class="playPauseBtn">
						${this.isPlaying ? pauseAudio : playAudio}
					</button>
					<div class="timeInfo">
						<span class="currentTime">0:00</span> / <span class="duration">3:07</span>
					</div>
					<input type="range" class="seekBar" value="0"/>
					<audio
						id="audio"
						src="${this.file.url}"
						@loadedmetadata="${this.loadMetadata}"
						@timeupdate="${this.updateTime}"
					></audio>
				</div>
			</div>
		`;
	}
}
