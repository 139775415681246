import {LitElement, html, css} from 'lit';
import {classMap} from 'lit/directives/class-map.js';
import {msg} from '@lit/localize';
import {customElement, property} from 'lit/decorators.js';
import {when} from 'lit/directives/when.js';
import {connect} from 'pwa-helpers';
import store from '../../store';
import style from '../../../../scss/menuOverlay.scss';
import {logout} from '../../slices/accountSlice';
import {selectLanguage} from '../../selectors';

@customElement('arc-menu-overlay')
export class MenuOverlay extends connect(store)(LitElement) {
	static styles = [style];

	static properties = {
		isOpen: {type: Boolean},
		loggedIn: {type: Boolean},
		profileDisplayName: {type: String},
		isAccordionOpen: {type: Boolean},
	};

	constructor() {
		super();
		store.subscribe(() => this.requestUpdate());
		this.isAccordionOpen = false;
	}

	stateChanged(state) {
		this.loggedIn = state.account.data.loggedIn;
		this.account = state.account.data.account;
		this.lang = selectLanguage(state);
	}

	onLogout(input) {
		store.dispatch(logout());
		this.closeMenu();
	}

	closeMenu() {
		this.isOpen = false;
		this.dispatchEvent(new CustomEvent('menu-closed', {detail: {isOpen: false}}));
	}

	// accordion not used for now
	//toggleAccordion() {
	//	this.isAccordionOpen = !this.isAccordionOpen;
	//	this.requestUpdate();
	//}

	render() {
		return html`
			<div class="menuOverlay ${classMap({show: this.isOpen})}">
				<nav class="menuLinks">
					<div class="menuLink mobile">
						<arc-language-switch @click=${this.closeMenu}></arc-language-switch>
					</div>
					${this.loggedIn
						? html`
								${this.profileDisplayName}
								<div class="menuLink">
									<arc-routerlink
										route="artworks"
										.params=${{id: 'Artworks'}}
										noUnderline
										whiteStyle
										@click=${this.closeMenu}
									>
										${msg('Artworks')}
									</arc-routerlink>
								</div>
								<div class="menuLink">
									<arc-routerlink
										route="artist"
										.params=${{language: 'en', id: this.account?.id, mode: 'edit'}}
										noUnderline
										whiteStyle
										@click=${this.closeMenu}
									>
										${msg('Edit Profile')}
									</arc-routerlink>
								</div>
								<div class="menuLink">
									<arc-routerlink
										route="account"
										.params=${{id: this.account?.id}}
										noUnderline
										whiteStyle
										@click=${this.closeMenu}
									>
										${msg('Account Settings')}
									</arc-routerlink>
								</div>

								<!-- commented out ACCORDION if needed for the future
								<div class="accordion menuLink">
									<div class="accordionHeader" @click=${this.toggleAccordion}>
										${this.profileDisplayName}
										<svg
											width="16"
											height="16"
											viewBox="0 0 20 20"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M19 9l-7 7-7-7"
												stroke="currentColor"
												stroke-width="2"
												stroke-linecap="round"
												stroke-linejoin="round"
											/>
										</svg>
									</div>
									<div class="accordionContent ${classMap({open: this.isAccordionOpen})}">
										<div class="accordionItem">
											<arc-routerlink
												route="artist"
												.params=${{language: 'en', id: this.account?.id, mode: 'edit'}}
												noUnderline
												whiteStyle
											>
												${msg('Edit Profile')}
											</arc-routerlink>
										</div>
										<div class="accordionItem">
											<arc-routerlink
												route="account"
												.params=${{id: this.account?.id}}
												noUnderline
												whiteStyle
											>
												${msg('Account Settings')}
											</arc-routerlink>
										</div>
									</div>
								</div>!-->
						  `
						: html``}
					${this.loggedIn
						? html`
								<div class="menuLink">
									<arc-link href="#" @click=${this.onLogout} noUnderline whiteStyle>
										${msg('Logout')}
									</arc-link>
								</div>
						  `
						: html`
								<div class="menuLink">
									<arc-routerlink route="requestToken" noUnderline whiteStyle>
										${msg('Login')}
									</arc-routerlink>
								</div>
						  `}
				</nav>
			</div>
		`;
	}
}
