import {connect} from 'pwa-helpers';
import {LitElement, html} from 'lit';
import {navigator, router} from 'lit-element-router';
import {customElement} from 'lit/decorators/custom-element.js';
import {repeat} from 'lit/directives/repeat.js';
import {msg} from '@lit/localize';
import deepEqual from 'deep-equal';

import store from '../../store';
import style from '../../../../scss/invitedList.scss';
import {
	selectAccountsQuery,
	selectLanguage
} from '../../selectors';
import getRoutes from '../../routes';
import down from '../../icons/down';
import up from '../../icons/up';
import {
	fetchInvitedAccounts,
	selectAllInvitedAccounts
} from '../../slices/accountSlice';
import {deepClone} from '../../util/deepClone';

@customElement('arc-invited-list')
export class InvitedList extends connect(store)(router(navigator(LitElement))) {
	static styles = [style];

	static properties = {
		showSearch: {type: Boolean},
		showFilter: {type: Boolean},
		accounts: {type: Array}
	};

	constructor() {
		super();
		this.currentLanguage = 'de';
		this.accounts = [];
		this.showSearch = false;
		this.showFilter = false;
		this.searchText = '';
		this.filtersVisible = false;
		store.subscribe(() => this.requestUpdate());
	}

	stateChanged(state) {
		this.state = state;
		this.status = state.status;
		this.lang = selectLanguage(state);
		this.accounts = selectAllInvitedAccounts(state);
		this.accountsQuery = selectAccountsQuery(state);
		if (this.updateNeeded) {
			this.updateNeeded = false;
			this.fetchInvitedAccounts();
		}
	}
	fetchInvitedAccounts() {
		store.dispatch(fetchInvitedAccounts({...this.query}));
	}

	static get routes() {
		return getRoutes();
	}

	router(route, params, query, data) {

		// do accounts need update from backend
		this.updateNeeded = (route === 'account') &&
			(!deepEqual(this.query, query));

		this.params = params;
		this.query = query;
		this.data = data;
	}

	connectedCallback() {
		super.connectedCallback();
	}

	disconnectedCallback() {
		super.disconnectedCallback();
	}

	updateSearch(event) {
		this.searchText = event.target.value.trim().toLowerCase();
	}

	getOrderQuery(orderBy) {
		return {
			...this.query,
			...{
				orderBy: orderBy,
				orderByDirection: this.accountsQuery.orderByDirection === 'desc' ? 'asc' : 'desc'
			}
		};
	}

	getOrderDirectionIcon(orderBy) {
		if (this.query.orderBy === orderBy) {
			return this.accountsQuery.orderByDirection === 'asc' ? down : up;
		} else {
			return '';
		}
	}

	render() {
		return html`
			<arc-account-list-filter .showSearch="${this.showSearch}" .showFilter="${this.showFilter}"></arc-account-list-filter>
			<div class="invitedList">
				${this.accounts.length > 0
					? html`
						<div class="invitedListHeader">
							<div>${msg('Name')}</div>
							<div>${msg('eMail')}</div>
							<div>${msg('has logged in')}</div>
							<div>${msg('Profile Permissions')}</div>
							<div>${msg('Artwork Permissions')}</div>
						</div>

						${repeat(
							this.accounts,
							(account) => account.id,
							(account, index) => html`
								<div class="invitedListItem">
									<arc-invited-list-item .accountId=${account.id}></arc-invited-list-item>
								</div>`
						)}
					`
					: html` <p class="emptyMessage">${msg('No accounts found.')}</p> `}
			</div>
		`;
	}
}
