import {html, css, LitElement} from 'lit';
import {customElement} from 'lit/decorators/custom-element.js';
import {connect} from 'pwa-helpers';
import store from '../store';
import style from '../../../scss/breadcrumbs.scss';

@customElement('arc-breadcrumbs')
export class Breadcrumbs extends connect(store)(LitElement) {
	static styles = [style];

	render() {
		return html`
			<slot class="breadcrumbs">

			</slot>
		`;
	}

}
