import {html} from 'lit';
import {customElement, property} from 'lit/decorators.js';
import {classMap} from 'lit/directives/class-map.js';
import {when} from 'lit/directives/when.js';
import deepEqual from 'deep-equal';

import {getValueForLanguage} from '../../../util/getValueForLanguage';

import style from '../../../../../scss/multilingualInput.scss';
import {FormElement} from './formElement';
import {
	selectAvailableContentLanguages,
	selectCurrentContentLanguage,
	selectProfileDefaultLanguage
} from '../../../selectors';
import store from '../../../store';

@customElement('arc-multilingual-input')
export class MultilingualInput extends FormElement {

	static properties = {
		value: {type: Array, hasChanged: (n, o) => !deepEqual(n, o)}, // initial value
		errors: {type: Array, hasChanged: (n, o) => !deepEqual(n, o)},
		placeholder: {type: String},
		title: {type: String},
		contentLanguage: {type: String},
		multiline: {type: Boolean},
		edit: {type: Boolean},
		required: {type: Boolean},
		tooltip: {type: String},

		index: {type: Number}, // index of the element when part of repeat
		type: {type: String} // type of data edited by form element
	};

	static styles = [...FormElement.styles, style];

	initialValue = [{lang: 'en', value: ''}, {lang: 'de', value: ''}];

	constructor() {
		super();
		this.languages = [];
	}

	stateChanged(state) {
		super.stateChanged(state);
		this.state = state;

		// if value is string, parse to object (migration)
		if (typeof this.value === 'string') {
			this.value = JSON.parse(this.value);
		}
		this.languages = selectAvailableContentLanguages(state);
		this.contentLanguage = '' + selectCurrentContentLanguage(this.state) ?? this.languages[0];
		store.subscribe(() => this.requestUpdate());
	}
	connectedCallback() {
		super.connectedCallback();
		window.addEventListener('content-language', this.handleContentLanguage.bind(this));
	}
	disconnectedCallback() {
		super.disconnectedCallback();
		window.removeEventListener('content-language', this.handleContentLanguage.bind(this));
	}


	handleValueChange(e) {
		const input = e.target;
		const value = input.value;
		let newValue = [];
		if (this.value === undefined || this.value === null) {
			this.value = [];
		}
		this.languages.forEach((lang) => {
			const oldValue = this.value.find((v) => v.lang === lang) ?? '';
			newValue.push({
				lang: lang,
				value: lang === this.contentLanguage ? value : (oldValue ? oldValue.value : '')
			});
		});
		// filter out items with empty string value
		newValue = newValue.filter((v) => v.value !== '');
		this.dispatchValueChange(newValue);
	}

	handleContentLanguage(e) {
		this.contentLanguage = e.detail.language;
	}
	handleLanguageChange(value) {
		this.contentLanguage = value;
	}
	hasContent(lang) {
		const value = this.value?.find((v) => v.lang === lang)?.value;
		return (value && value !== '');
	}
	render() {
		return html`
			<div class="formField multilingual-input ${classMap({errors: this.hasErrors(), changed: this.inputHasChanged(), required: this.required, disabled: this.disabled})}">
				<div class="textInputInfo">
					<div class="title">
						${this.title}
						${when(this.required, () => html`<span class="requiredMark">*</span>`)}
					</div>
					${when(this.tooltip, () => html`<arc-tooltip text=${this.tooltip}></arc-tooltip>`)}
				</div>
				<div class="language-switcher">
						${this.languages.map(lang => html`
							<button class="language-switcher-button ${classMap({active: this.contentLanguage === lang, noContent: !this.hasContent(lang)})}"
											@click=${() => this.handleLanguageChange(lang)}>
								${lang}
							</button>
						`)}
				</div>
				<div class="input-wrapper">
					${when(this.multiline,
						() => html`
							<textarea
								placeholder="${this.placeholder}"
								@input=${this.handleValueChange}
								?disabled=${this.disabled}
								.value=${getValueForLanguage(this.value, this.contentLanguage, false)}
						/></textarea>`,
						() => html`
							<input
								placeholder="${this.placeholder}"
								@input=${this.handleValueChange}
								?disabled=${this.disabled}
								.value=${getValueForLanguage(this.value, this.contentLanguage, false)}
							/>`
					)}
				</div>
				${when(this.hasErrors(), () => html`<arc-errors .errors=${this.errors}></arc-errors>`)}
			</div>
		`;
	}


}
