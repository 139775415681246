import {html} from 'lit';
import {customElement} from 'lit/decorators/custom-element.js';
import {classMap} from 'lit/directives/class-map.js';
import {msg} from '@lit/localize';
import deepEqual from 'deep-equal';
import store from '../../../store';
import style from '../../../../../scss/inventoryNumber.scss';
import {FormElement} from './formElement';

@customElement('arc-inventory-number')
export class InventoryNumber extends FormElement {
	static properties = {
		value: {type: Object},
		errors: {type: Array, hasChanged: (n, o) => !deepEqual(n, o)},

		index: {type: Number}, // index of the element when part of repeat
		type: {type: String}, // type of data edited by form element
	};

	static styles = [...FormElement.styles, style];

	initialValue = {
		name: '',
		value: '',
	};

	constructor() {
		super();
		this.value = this.initialValue;
	}

	inputName(value) {
		this.inputValue.name = value;
		this.dispatchValueChange(this.inputValue);
	}
	inputAValue(value) {
		this.inputValue.value = value;
		this.dispatchValueChange(this.inputValue);
	}

	render() {
		const s = store.getState();
		if (!this.inputValue) return html``;
		return html`
			<div
				class="inventoryNumber ${classMap({
					errors: this.hasErrors(),
					changed: this.inputHasChanged(),
					required: this.required,
					disabled: this.disabled
				})}"
			>
				<arc-text-input
					class="name"
					id="name"
					class="formInput name"
					.title="${msg('Label')}"
					.multiline=${false}
					.value=${this.inputValue.name}
					.errors=${this.errors?.params?.name?.errors || []}
					.required=${true}
					@value-change=${(e) => this.inputName(e.detail.value)}
				></arc-text-input>
				<arc-text-input
					class="value"
					id="value"
					class="formInput value"
					.title="${msg('Number')}"
					.multiline=${false}
					.value=${this.inputValue.value}
					.errors=${this.errors?.params?.unit?.errors || []}
					.required=${true}
					@value-change=${(e) => this.inputAValue(e.detail.value)}
				></arc-text-input>
			</div>
		`;
	}
}
