// return display name of profile
export function profileDisplayName(profile) {
	if (!profile?.groupInfo && !profile?.singleInfo) {
		return profile?.displayName;
	}
	if (profile?.isGroup) {
		return profile?.groupInfo?.name;
	}
	if (profile?.singleInfo) {
		return profile?.singleInfo?.penName ?
			profile?.singleInfo?.penName :
			profile?.singleInfo?.firstName + ' ' + profile?.singleInfo?.lastName;
	}
	return profile?.id;
}
