import Cookies from 'js-cookie';

export default function() {

	const header = 'arc-sid';
	let sid = null;

	// the actual middleware
	return function sidMiddleware(req) {
		// optionally set the sid token
		sid = Cookies.get('arc-sid');
		if (sid) req.set(header, sid);

		// retain reference to old callback
		const callback = req.callback;

		// patch callback to capture token
		req.callback = function(err, res) {
			if (err || !res) return callback.call(req, err, res);

			sid = res.headers[header];
			Cookies.set('arc-sid', sid, {expires: 180});
			// invoke original callback ref
			return callback.call(req, err, res);
		};
	};

}
