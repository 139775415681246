import {LitElement, html} from 'lit';
import {customElement} from 'lit/decorators.js';
import {connect} from 'pwa-helpers';
import {msg} from '@lit/localize';
import {when} from 'lit/directives/when.js';
import store from '../../store';
import style from '../../../../scss/artworkMediaItem.scss';
import textInputStyle from '../../../../scss/textInput.scss';
import multilingualFieldStyle from '../../../../scss/multilingualInput.scss';
import {deepClone} from '../../util/deepClone';
import {selectProfileDefaultLanguage} from '../../selectors';
import viewPdf from '../../icons/viewPdf';

@customElement('arc-artwork-media-item')
export class ArtworkMediaItem extends connect(store)(LitElement) {
	static properties = {
		mode: {type: String},
		index: {type: Number},
		value: {type: Object},
		errors: {type: Array},
		artworkId: {type: String},
		disabled: {type: Boolean},
		previewUrl: {type: String},
		fileName: {type: String},
	};

	static styles = [style, textInputStyle, multilingualFieldStyle];

	constructor() {
		super();
		this.value = null;
		this.mediaCollection = [];
		this.index = 0;
		this.artworkId = '';
		this.disabled = false;
		this.currentLanguage = 'de';
		this.previewUrl = '';
		this.fileName = '';

		store.subscribe(() => this.requestUpdate());
	}

	stateChanged(state) {
		this.currentLanguage = selectProfileDefaultLanguage(state);

		if (this.value && !this.value.info.title && this.value.filename) {
			const fileNameWithoutExtension = this.value.filename.split('.').slice(0, -1).join('.');
			this.value.info = {
				...this.value.info,
				title: [{lang: this.currentLanguage, value: fileNameWithoutExtension}],
			};
			this.dispatchValueChange(); // Ensure to dispatch the change after setting the title
		}
	}

	handleValueChange(fieldData, value) {
		const newData = deepClone(this.value);

		const {section, field} = fieldData;

		if (section) {
			newData[section] = deepClone(newData[section] || {});
			if (newData[section][field]) {
				newData[section][field] = value;
			} else {
				newData[section][field] = value;
			}
		} else {
			newData[field] = value;
		}
		this.value = newData;
		this.dispatchValueChange();
	}

	dispatchValueChange() {
		this.dispatchEvent(
			new CustomEvent('value-change', {
				detail: {
					index: this.index,
					value: this.value,
				},
			})
		);
	}

	handleRemoveFile() {
		this.dispatchEvent(
			new CustomEvent('remove-file', {
				detail: {
					fileId: this.value.id,
				},
			})
		);
	}
	handleReplaceFile(file) {
		this.dispatchEvent(
			new CustomEvent('replace-file', {
				detail: {
					fileId: this.value.id,
					file: file,
				},
			})
		);
	}

	render() {
		if (!this.previewUrl && this.value && this.value.url && this.value.ext === 'pdf') {
			this.previewUrl = this.value.url;
		}
		const mediaItemFields = [
			{
				type: 'multilingualTextInput',
				title: msg('Caption'),
				field: 'title',
				section: 'info',
				placeholder: '',
				currentLanguage: this.currentLanguage,
				data: this.value,
				errors: this.errors,
				required: false,
				handler: this.handleValueChange.bind(this),
			},
			{
				type: 'multilingualTextInput',
				title: msg('Description'),
				field: 'description',
				section: 'info',
				placeholder: '',
				currentLanguage: this.currentLanguage,
				multiline: true,
				data: this.value,
				errors: this.errors,
				required: false,
				handler: this.handleValueChange.bind(this),
			},
			{
				type: 'multilingualTextInput',
				title: msg('Alt Text'),
				field: 'alt',
				section: 'info',
				placeholder: '',
				currentLanguage: this.currentLanguage,
				data: this.value,
				errors: this.errors,
				required: false,
				handler: this.handleValueChange.bind(this),
			},
			{
				type: 'multilingualTextInput',
				title: msg('Media Copyright'),
				field: 'mediaCopyright',
				section: 'info',
				placeholder: '',
				currentLanguage: this.currentLanguage,
				data: this.value,
				errors: this.errors,
				required: false,
				handler: this.handleValueChange.bind(this),
			},
			{
				type: 'multilingualTextInput',
				title: msg('Additional Copyright'),
				field: 'artworkCopyright',
				section: 'info',
				placeholder: '',
				currentLanguage: this.currentLanguage,
				data: this.value,
				errors: this.errors,
				required: false,
				handler: this.handleValueChange.bind(this),
			},
			{
				type: 'radio',
				title: msg('Publication'),
				field: 'visibility',
				section: '',
				options: [
					{
						id: 'public',
						name: [
							{lang: 'de', value: 'öffentlich'},
							{lang: 'en', value: 'public'},
						],
					},
					{
						id: 'private',
						name: [
							{lang: 'de', value: 'privat'},
							{lang: 'en', value: 'private'},
						],
					},
				],
				data: this.value,
				errors: this.errors,
				required: false,
				handler: this.handleValueChange.bind(this),
			},
		];

		const formatSize = (size) => {
			if (size < 1024) return `${size} B`;
			if (size < 1024 * 1024) return `${(size / 1024).toFixed(2)} KB`;
			return `${(size / (1024 * 1024)).toFixed(2)} MB`;
		};

		const formatDuration = (duration) => {
			const minutes = Math.floor(duration / 60);
			const seconds = Math.floor(duration % 60);
			return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
		};

		const isImage = ['jpg', 'jpeg', 'png', 'gif'].includes(this.value.ext);
		const isAudio = ['mp3', 'wav', 'ogg'].includes(this.value.ext);
		const isPDF = this.value.ext === 'pdf';

		return html`
			<div class="mediaItemContainer">
				<div class="mediaItem">
					<!-- Image Preview -->

					${
						this.value.ext &&
						this.value.thumbs &&
						(this.value.ext === 'jpg' || this.value.ext === 'png' || this.value.ext === 'jpeg')
							? html`
									<div class="imagePreview">
										<p class="mediaIndex">${this.index + 1}.</p>
										<arc-image .file=${this.value} alt="Main Image"></arc-image>
									</div>
							  `
							: ''
					}

					<!-- PDF Preview -->
					${
						this.value.ext === 'pdf' && this.previewUrl
							? html`
									<div class="pdfPreview">
										<p class="mediaIndex">${this.index + 1}.</p>
										<div class="pdfPreviewContainer">
											<a href="${this.previewUrl}" target="_blank" class="viewPdfButton"
												><span class="pdfIcon">${viewPdf}</span>${msg(' View PDF')}</a
											>
										</div>
									</div>
							  `
							: ''
					}

					<!-- Video Preview -->
					${when(
						this.value.ext === 'mp4',
						() => html`
							<div class="videoPreview">
								<p class="mediaIndex">${this.index + 1}.</p>
								<arc-video .file=${this.value} alt="Video Preview"></arc-video>
							</div>
						`
					)}

					<!-- Audio Preview -->
					${when(
						['mp3', 'wav', 'ogg'].includes(this.value.ext),
						() => html`
							<div class="audioPreview">
								<p class="mediaIndex">${this.index + 1}.</p>
								<arc-audio .file=${this.value} alt="Audio Preview"></arc-audio>
							</div>
						`
					)}

					<div class="fileData">
						<p>${this.value.originalName || 'N/A'}</p>
						<p>${this.value.size ? formatSize(this.value.size) : 'N/A'}
						${
							isImage
								? html`
										/ ${this.value.info?.width || 'N/A'} x ${this.value.info?.height || 'N/A'} px</p>
								  `
								: ''
						}
						${
							isAudio
								? html`
										/ ${this.value.info?.duration ? formatDuration(this.value.info.duration) : 'N/A'}
										</p>
								  `
								: ''
						}
						${isPDF ? html`` : ''}
						/ ${this.value.ext || 'N/A'}</p>
					</div>

					<!-- File Upload/Edit Section -->
				</div>
				<div class="mediaItemFormContainer">
					${
						!this.value.url
							? html``
							: html`
									<arc-form-grid
										class="mediaItemForm"
										.fields=${mediaItemFields}
										.localFormData=${this.value}
										.value=${this.value}
										.errors=${this.errors}
										?disabled="${this.disabled}"
									>
									</arc-form-grid>
							  `
					}
				</div>
				<arc-dropdown-menu>
					<arc-button
						type="secondary"
						@click=${this.handleRemoveFile}
						?disabled="${this.disabled}"
						title="${msg('Remove Item')}">
					</arc-button>
					<arc-image-upload
						addLabel="${msg('Replace File')}"
						?disabled="${this.disabled}"
						acceptedFormats="image/*,video/*,application/pdf"
						@add-file=${(e) => this.handleReplaceFile(e.detail.file)}
					></arc-image-upload>
				</arc-dropdown-menu>
			</div>
			</div>
			</div>
		`;
	}
}
