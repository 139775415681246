import {connect} from 'pwa-helpers';
import {LitElement, html} from 'lit';
import {customElement} from 'lit/decorators/custom-element.js';
import {msg} from '@lit/localize';
import {router} from 'lit-element-router';
import {when} from 'lit/directives/when.js';

import store from '../../store';

import style from '../../../../scss/welcome.scss';
import {selectCurrentProfileId, selectIsOwnerOfCurrentProfile, selectUserRole} from '../../selectors';
import getRoutes from '../../routes';

@customElement('arc-welcome')
export class Welcome extends connect(store)(router(LitElement)) {
	static styles = [style];

	constructor() {
		super();
		store.subscribe(() => this.requestUpdate());
	}

	stateChanged(state) {
		this.status = state.status;
		this.profileId = selectCurrentProfileId(state);
		this.role = selectUserRole(state);
	}

	static get routes() {
		return getRoutes();
	}
	async router(route, params, query, data) {
		this.params = params;
	}
	async connectedCallback() {
		super.connectedCallback();
	}

	disconnectedCallback() {
		super.disconnectedCallback();
	}

	render() {
		const state = store.getState();
		return html`
			<div class="welcomeScreen">
				<div class="welcomeContent">
					<div class="welcomeText">
						<h2>${msg('Welcome to Werkverzeichnisse of Stiftung Kunstfonds')}</h2>
						<slot></slot>
					</div>
					<div class="welcomeButtons">
						${when(this.role === 'user' && this.profileId,
							() => html`
							<arc-routerlink route="artist" .params=${{id: this.profileId}} noUnderline buttonStyle>
								${msg('Edit Your Profile')}
							</arc-routerlink>
							<arc-routerlink route="artworks" .params=${{id: this.profileId}} noUnderline buttonStyle>
								${msg('Edit Artworks')}
							</arc-routerlink>
							${when(selectIsOwnerOfCurrentProfile(state), () => html`
								<arc-routerlink route="artist-share" .params=${{id: this.profileId}} noUnderline buttonStyle>
									${msg('Invite Editor')}
								</arc-routerlink>
							`)}
						`)}
						${when(this.role === 'admin' || this.role === 'operator',
							() => html`
							<arc-routerlink route="admin-accounts" noUnderline buttonStyle>
								${msg('Manage Accounts')}
							</arc-routerlink>
						`)}
					</div>
				</div>
				<div class="welcomeImage">
					<img src="../assets/img/loginImg.jpg" />
				</div>
			</div>
    `;
	}
}
