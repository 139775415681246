import {LitElement, html, css} from 'lit';
import {customElement, property} from 'lit/decorators.js';
import {when} from 'lit/directives/when.js';
import {connect} from 'pwa-helpers';
import {msg} from '@lit/localize';
import deepEqual from 'deep-equal';
import store from '../../store';
import style from '../../../../scss/cventry.scss';
import textInputStyle from '../../../../scss/textInput.scss';
import multilingualFieldStyle from '../../../../scss/multilingualInput.scss';
import {deepClone} from '../../util/deepClone';
import {selectProfileDefaultLanguage} from '../../selectors';

@customElement('arc-cv-entry')
export class CvEntry extends connect(store)(LitElement) {
	static properties = {
		index: {type: Number},
		value: {type: Object, hasChanged: (n, o) => !deepEqual(n, o)},
		errors: {type: Array, hasChanged: (n, o) => !deepEqual(n, o)},
		edit: {type: Boolean},
		disabled: {type: Boolean}
	};

	static styles = [style, textInputStyle, multilingualFieldStyle];

	inititalValue = {
		city: '',
		link: '',
		title: [{value: '', lang: 'de'}],
		dateTo: '',
		dateFrom: '',
		location: '',
		description: null,
		isImportant: 0
	};

	constructor() {
		super();
		this.edit = false;
		this.disabled = false;
		store.subscribe(() => this.requestUpdate());
	}

	stateChanged(state) {
		this.lang = state.status.lang;
	}

	connectedCallback() {
		super.connectedCallback();
		if (!this.value) {
			this.value = deepClone(this.inititalValue);
		}
		if (this.errors && this.errors.length > 0) {
			this.edit = true;
		}
	}

	getInitialValue() {
		return this.inititalValue;
	}

	toggleEdit() {
		if (this.errors && this.errors.length > 0) {
			this.edit = true;
		} else {
			this.edit = !this.edit;
		}
		this.requestUpdate();
	}

	handleValueChange(fieldData, value) {
		const section = fieldData.section;
		const field = fieldData.field;

		// update value with new value in section and field
		// checkbox: convert string to number if it is a integer
		if (fieldData.type === 'checkbox' && typeof value === 'string' && value.match(/^\d+$/))
			value = parseInt(value, 10);

		if (section) {
			if (this.value[section]?.[field]) {
				this.value[section][field] = value; // update field
			} else {
				this.value[section] = {...this.value[section], [field]: value}; // create new field
			}
		} else {
			this.value[field] = value; // update field
		}
		this.requestUpdate('value');
		this.dispatchValueChange(this.value);
	}

	dispatchValueChange(value) {
		this.dispatchEvent(
			new CustomEvent('value-change', {
				detail: {
					index: this.index,
					value: value
				}
			})
		);
	}

	updated(changedProperties) {
		if (changedProperties.has('errors')) {
			if (this.checkForErrors(this.errors.params)) {
				this.edit = true; // keep the entry open if there are errors
			}
		}
	}

	// check for errors - to keep the accordion open if there are some
	checkForErrors(obj) {
		if (Array.isArray(obj)) {
			return obj.some((item) => this.checkForErrors(item));
		}
		if (typeof obj === 'object' && obj !== null) {
			if (obj.errors && obj.errors.length > 0) {
				return true;
			}
			return Object.values(obj).some((value) => this.checkForErrors(value));
		}
		return false;
	}

	render() {
		const cvEntryInputFields = [
			{
				type: 'date',
				title: msg('Year (Start)'),
				field: 'dateFrom',
				section: '',
				granularity: 'year',
				data: this.value,
				errors: this.errors,
				required: false,
				handler: this.handleValueChange.bind(this)
			},
			{
				type: 'date',
				title: msg('Year (End)'),
				field: 'dateTo',
				section: '',
				granularity: 'year',
				data: this.value,
				errors: this.errors,
				required: false,
				handler: this.handleValueChange.bind(this)
			},
			{
				type: 'checkbox',
				title: msg('Mark as important'),
				field: 'isImportant',
				section: '',
				tooltip: '',
				data: this.value,
				errors: this.errors,
				handler: this.handleValueChange.bind(this)
			},
			{
				type: 'multilingualTextInput',
				title: msg('Title'),
				field: 'title',
				section: '',
				placeholder: '',
				data: this.value,
				errors: this.errors,
				required: false,
				handler: this.handleValueChange.bind(this)
			},
			{
				type: 'textInput',
				title: msg('Institution or Location'),
				field: 'location',
				section: '',
				placeholder: '',
				data: this.value,
				errors: this.errors,
				required: false,
				handler: this.handleValueChange.bind(this)
			},
			{
				type: 'textInput',
				title: msg('City, Country'),
				field: 'city',
				section: '',
				placeholder: '',
				data: this.value,
				errors: this.errors,
				required: false,
				handler: this.handleValueChange.bind(this)
			},
			{
				type: 'textInput',
				title: msg('Link'),
				field: 'link',
				section: '',
				placeholder: 'https://',
				data: this.value,
				errors: this.errors,
				required: false,
				handler: this.handleValueChange.bind(this)
			},
			{
				type: 'multilingualTextInput',
				title: msg('Description'),
				field: 'description',
				section: '',
				placeholder: '',
				multiline: true,
				data: this.value,
				errors: this.errors,
				required: false,
				handler: this.handleValueChange.bind(this)
			}
		];

		return html`
			<div class="entry-container">
				${when(
					this.edit,
					() => html`
						<arc-form-grid
							class="cvEntry"
							.fields=${cvEntryInputFields}
							.value=${this.value}
							?disabled="${this.disabled}"
							.errors=${this.errors}
						>
						</arc-form-grid>
						<arc-button
							title="${msg('Done')}"
							type="primary"
							.additionalInfo="${false}"
							@click=${this.toggleEdit}
						>
						</arc-button>`,
					() => html`
						<arc-artist-cv-item
							class="cvItem"
							@click=${this.toggleEdit}
							.entry=${this.value}
						></arc-artist-cv-item>
					`
				)}
			</div>
		`;
	}
}
