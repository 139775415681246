import {connect} from 'pwa-helpers';
import {LitElement, html, css} from 'lit';
import {customElement} from 'lit/decorators/custom-element.js';
import {router} from 'lit-element-router';
import {classMap} from 'lit/directives/class-map.js';
import {repeat} from 'lit/directives/repeat.js';

import store from '../../store';

import getRoutes from '../../routes';
import style from '../../../../scss/languageSwitch.scss';

@customElement('arc-language-switch')
export class LanguageSwitch extends connect(store)(router(LitElement)) {
	static styles = [style];

	static properties = {
		params: {type: Object}
	};

	languages = ['de', 'en'];

	static get routes() {
		return getRoutes();
	}

	constructor() {
		super();
		store.subscribe(() => this.requestUpdate());
	}

	router(route, params, query, data) {
		this.params = params;
		this.route = route;
	}

	stateChanged(state) {
	}

	render() {
		return html`
			<div class="languages">
				${repeat(
					this.languages,
					(language) => language,
					(language) => html`
						<arc-routerlink
							class="language ${classMap({active: this.params.language === language})}"
							.params=${{language: language}}
							noUnderline>
							${language.toUpperCase()}
						</arc-routerlink>
					`)}
			</div>
    `;
	}
}
