import {connect} from 'pwa-helpers';
import {LitElement, html} from 'lit';
import {customElement} from 'lit/decorators.js';
import {when} from 'lit/directives/when.js';
import {msg, str} from '@lit/localize';
import {navigator, router} from 'lit-element-router';
import {repeat} from 'lit/directives/repeat.js';
import {classMap} from 'lit/directives/class-map.js';
import deepEqual from 'deep-equal';
import {deepClone} from '../../util/deepClone';

import getRoutes from '../../routes';
import store from '../../store';
import style from '../../../../scss/artworkMedia.scss';
import loadingSpinnerStyle from '../../../../scss/loadingSpinner.scss';
import {
	fetchMediaCollection,
	selectAllMedia,
	massUpdateMedia,
	selectArtworkById,
	fetchArtwork,
	createMedia,
	deleteMedia,
	replaceMedia,
} from '../../slices/artworkSlice';
import {getValueForLanguage} from '../../util/getValueForLanguage';
import {selectProfileDefaultLanguage, selectLanguage} from '../../selectors';
import arrowDown from '../../icons/arrowDown';
import arrowUp from '../../icons/arrowUp';
import {setFormChanged, showMessage} from '../../slices/statusSlice';
import formPageStyle from '../../../../scss/formPage.scss';
import {isPermitted} from '../../util/isPermitted';

@customElement('arc-artwork-media')
export class ArtworkMedia extends connect(store)(router(navigator(LitElement))) {
	static styles = [formPageStyle, loadingSpinnerStyle, style];

	static properties = {
		mediaCollection: {type: Array},
		localFormData: {type: Object, hasChanged: (n, o) => !deepEqual(n, o)},
		artworkId: {type: String},
		isLoading: {type: Boolean},
		disabled: {type: Boolean},
		artwork: {type: Object},
		parentArtwork: {type: Object},
		formChanged: {type: Boolean},
		amountLimits: {type: Object},
	};

	constructor() {
		super();
		this.mediaCollection = [];
		this.localFormData = [];

		this.artworkId = null;
		this.isLoading = true;
		this.disabled = false;
		this.currentLanguage = 'de';
		this.artwork = null;
		this.parentArtwork = null;
		this.allowedTypes = {};
		this.amountLimits = {};
	}

	stateChanged(state) {
		this.state = state;
		this.mediaCollection = selectAllMedia(state);
		this.isLoading = state.artwork.loading === 'loading';
		this.artwork = selectArtworkById(this.state, this.artworkId);

		this.permittedToView = isPermitted(['artwork/view'], this.profileId, state);
		this.permittedToEdit = isPermitted(['artwork/edit'], this.profileId, state);

		this.currentLanguage = selectProfileDefaultLanguage(state);
		this.lang = selectLanguage(state);
		this.formChanged = state.status.formChanged;
	}

	static get routes() {
		return getRoutes();
	}

	async router(route, params) {
		this.edit = true; // params.mode === 'edit';
		this.profileId = params.id;
		if (route === 'artwork-media' && String(this.artworkId) !== params.artworkId) {
			this.artworkId = parseInt(params.artworkId, 10);
			const mediaResponse = await store.dispatch(fetchMediaCollection({artworkId: this.artworkId}));
			this.localFormData = selectAllMedia(this.state, this.artworkId);

			if (mediaResponse.payload) {
				this.sizeLimits = mediaResponse.payload.collection.sizeLimits;
				this.durationLimits = mediaResponse.payload.collection.durationLimits;
				this.allowedTypes = mediaResponse.payload.collection.allowedTypes;
				this.amountLimits = mediaResponse.payload.collection.amountLimits || {};

				console.log('Allowed Types:', this.allowedTypes);
			}

			await store.dispatch(fetchArtwork({id: this.artworkId}));
			if (this.artwork.parentId) {
				store.dispatch(fetchArtwork({id: this.artwork.parentId}));
			}
		}
	}

	connectedCallback() {
		super.connectedCallback();

		// reset form changed status to allow navigation
		store.dispatch(setFormChanged(false));
	}

	async addFile(file) {
		// if there is a video uploaded already - prevent from uploading and show error
		const fileType = this.getFileType(file.type);
		if (fileType === 'video' && this.currentVideoCount >= (this.amountLimits?.video ?? Infinity)) {
			store.dispatch(
				showMessage({
					message: msg('You have reached the limit of video files.'),
					messageType: 'error',
				})
			);
			return;
		}
		// first check how many videos is there already
		const limitExceeded = await this.checkFileLimits(file);
		if (!limitExceeded) {
			if (this.formChanged) {
				await this.saveAllMedia(); // save current data before adding new one and updating collection
			}
			await store.dispatch(createMedia({artworkId: this.artworkId, file: file}));
			this.localFormData = selectAllMedia(this.state, this.artworkId);
		}
	}

	async checkFileLimits(file) {
		const fileType = this.getFileType(file.type);
		let limitExceeded = false;

		// check file size limits
		const maxSize = this.getSizeLimit(fileType);
		if (file.size > maxSize) {
			limitExceeded = true;
			store.dispatch(
				showMessage({
					message: msg(
						str`File size exceeds the limit of ${this.formatSize(maxSize)} for ${fileType} files.`
					),
					messageType: 'error',
				})
			);
		}

		// check video and audio duration limits
		if (fileType === 'audio' || fileType === 'video') {
			const duration = await this.getMediaDuration(file);
			const maxDuration = this.getDurationLimit(fileType);
			limitExceeded = limitExceeded || duration > maxDuration;
			if (duration > maxDuration) {
				store.dispatch(
					showMessage({
						message: msg(
							str`File duration exceeds the limit of ${
								maxDuration / 60
							} minutes for ${fileType} files.`
						),
						messageType: 'error',
					})
				);
			}
		}

		return limitExceeded;
	}

	getFileType(mime) {
		if (!mime) {
			return 'unknown';
		}

		const mimeMapping = {
			'audio/': 'audio',
			'image/': 'image',
			'video/': 'video',
			'application/pdf': 'document',
		};

		for (const prefix in mimeMapping) {
			if (mime.startsWith(prefix)) {
				return mimeMapping[prefix];
			}
		}

		return 'unknown';
	}

	getSizeLimit(fileType) {
		if (!this.sizeLimits) {
			return Infinity;
		}
		const globalLimit = this.sizeLimits[''] ? this.sizeToBytes(this.sizeLimits['']) : Infinity;
		const typeLimit = this.sizeLimits[fileType]
			? this.sizeToBytes(this.sizeLimits[fileType])
			: globalLimit;

		return Math.min(globalLimit, typeLimit);
	}

	getDurationLimit(fileType) {
		return this.durationLimits[fileType] || Infinity;
	}

	async getMediaDuration(file) {
		return new Promise((resolve) => {
			const mediaElement = document.createElement(
				file.type.startsWith('video') ? 'video' : 'audio'
			);
			const fileURL = URL.createObjectURL(file);
			mediaElement.src = fileURL;
			mediaElement.addEventListener('loadedmetadata', () => {
				const duration = mediaElement.duration;
				resolve(duration);
				URL.revokeObjectURL(fileURL);
			});
		});
	}

	sizeToBytes(size) {
		const len = size.length;
		const value = parseInt(size.slice(0, len - 1), 10);
		const unit = size[len - 1].toLowerCase();
		switch (unit) {
			case 'g':
				return value * 1024 * 1024 * 1024;
			case 'm':
				return value * 1024 * 1024;
			case 'k':
				return value * 1024;
			default:
				return value;
		}
	}

	formatSize(size) {
		if (size < 1024) return `${size} B`;
		if (size < 1024 * 1024) return `${(size / 1024).toFixed(2)} KB`;
		return `${(size / (1024 * 1024)).toFixed(2)} MB`;
	}

	async handleRemoveFile(fileId) {
		if (this.formChanged) {
			await this.saveAllMedia(); // save current data before adding new one and updating collection
		}
		await store.dispatch(deleteMedia({artworkId: this.artworkId, fileId: fileId}));
		this.localFormData = selectAllMedia(this.state, this.artworkId);
	}

	async handleReplaceFile(id, file) {
		if (file) {
			const fileType = this.getFileType(file.type);
			if (
				fileType === 'video' &&
				this.currentVideoCount >= (this.amountLimits?.video ?? Infinity)
			) {
				store.dispatch(
					showMessage({
						message: msg('You have reached the limit of video files.'),
						messageType: 'error',
					})
				);
				return false;
			}

			const limitExceeded = await this.checkFileLimits(file);
			if (limitExceeded) {
				return false;
			}

			if (this.formChanged) {
				await this.saveAllMedia();
			}

			await store.dispatch(replaceMedia({artworkId: this.artworkId, mediaId: id, file: file}));
			this.localFormData = selectAllMedia(this.state, this.artworkId);

			return true;
		}

		return false;
	}

	moveMedia(index, direction) {
		const updatedCollection = deepClone(this.localFormData);

		//swap position values
		if (direction === 'up' && index > 0) {
			const tempPos = updatedCollection[index].pos;
			updatedCollection[index].pos = updatedCollection[index - 1].pos;
			updatedCollection[index - 1].pos = tempPos;
		} else if (direction === 'down' && index < updatedCollection.length - 1) {
			const tempPos = updatedCollection[index].pos;
			updatedCollection[index].pos = updatedCollection[index + 1].pos;
			updatedCollection[index + 1].pos = tempPos;
		}
		this.localFormData = updatedCollection;
		store.dispatch(setFormChanged(this.formHasChanged()));
	}

	async saveAllMedia() {
		const mediaItems = this.localFormData.map((item) => {
			const description = (item.info.description || []).filter(
				(entry) => entry.value.trim() !== ''
			);
			const alt = (item.info.alt || []).filter((entry) => entry.value.trim() !== '');
			const artworkCopyright = (item.info.artworkCopyright || []).filter(
				(entry) => entry.value.trim() !== ''
			);
			const mediaCopyright = (item.info.mediaCopyright || []).filter(
				(entry) => entry.value.trim() !== ''
			);

			const filteredItem = {
				id: item.id,
				visibility: item.visibility || 'private',
				title: item.info.title,
				description: description,
				alt: alt,
				artworkCopyright: artworkCopyright,
				mediaCopyright: mediaCopyright,
				pos: item.pos,
			};

			['description', 'alt', 'artworkCopyright', 'mediaCopyright'].forEach((field) => {
				if (filteredItem[field].length === 0) {
					delete filteredItem[field];
				}
			});

			return filteredItem;
		});
		await store.dispatch(massUpdateMedia({artworkId: this.artworkId, items: mediaItems}));
		store.dispatch(setFormChanged(false));
	}

	handleItemChange(index, value) {
		const newData = deepClone(this.localFormData); // clone current date
		newData[index] = value;
		this.localFormData = newData; // assign (updates reference, triggers update)
		store.dispatch(setFormChanged(this.formHasChanged()));
	}

	formHasChanged() {
		// check if local form data is different
		const newData = deepClone(this.localFormData ?? null);
		const oldData = deepClone(selectAllMedia(this.state, this.artworkId) ?? null);
		return !deepEqual(newData, oldData);
	}

	get currentVideoCount() {
		return this.localFormData.filter((item) => {
			if (!item || !item.mime) {
				return false;
			}
			return this.getFileType(item.mime) === 'video';
		}).length;
	}

	get canAddMedia() {
		const maxAmount = this.amountLimits[''] || Infinity;
		const currentCount = this.localFormData.length;
		return currentCount < maxAmount;
	}

	render() {
		const title = this.artwork
			? getValueForLanguage(this.artwork.title, this.currentLanguage, true)
			: null;

		const sortedMediaCollection = deepClone(this.localFormData).sort((a, b) => a.pos - b.pos);

		return html`
			<div class="formPage artworkMedia">
				<arc-toolbar class="toolbar">
					<div slot="left">
						<arc-breadcrumbs>
							<arc-routerlink route="artworks">${msg('Artworks')}</arc-routerlink>
							${when(
								this.parentArtwork,
								() => html` <arc-routerlink
									route="artwork"
									.params=${{id: this.profileId, artworkId: this.parentArtwork.id, mode: 'edit'}}
								>
									${getValueForLanguage(this.parentArtwork.title, this.currentLanguage, true)}
								</arc-routerlink>`
							)}
							<arc-routerlink
								route="artwork"
								.params=${{id: this.profileId, artworkId: this.artworkId, mode: 'edit'}}
							>
								${title}
							</arc-routerlink>
							<span>${msg('Media')}</span>
						</arc-breadcrumbs>
					</div>
					<div slot="right">
						<arc-image-upload
							addLabel="${msg('Add Media')}"
							acceptedFormats="image/*,video/*,application/pdf,audio/*"
							@add-file=${(e) => this.addFile(e.detail.file)}
							?disabled=${!this.canAddMedia || !this.permittedToEdit}
						></arc-image-upload>
						<arc-button
							title="${msg('Save Media')}"
							type="primary"
							?disabled=${!this.formChanged || !this.permittedToEdit}
							@click=${this.saveAllMedia}
						></arc-button>
					</div>
				</arc-toolbar>

				<header>
					<h2 class="pageTitle">${msg('Edit Media')}</h2>
					<arc-content-language-switch></arc-content-language-switch>
					<div class="uploadLimits">
						${msg('File formats and upload limits:')}
						<p>
							<span class="${classMap({warn: this.localFormData.length >= this.amountLimits['']})}">
								${this.localFormData.length} / ${this.amountLimits['']} ${msg('Files')}
							</span>
							(${this.allowedTypes?.image?.join(', ') ?? msg('none')}: ${this.sizeLimits?.image ?? ''} /
							${this.allowedTypes?.audio?.join(', ') ?? msg('none')}: ${Math.floor(this.durationLimits?.audio / 60)} min /
							${this.allowedTypes?.document?.join(', ') ?? msg('none')}: ${this.sizeLimits?.document ?? ''} )
						</p>
						<p>
							<span class="${classMap({warn: this.currentVideoCount >= this.amountLimits?.video})}">
								${this.currentVideoCount} / ${this.amountLimits?.video} ${msg('Videos')}
							</span>
							(${this.allowedTypes?.video?.join(', ') ?? msg('none')}: ${Math.floor(this.durationLimits?.video / 60)} min)
						</p>
					</div>
				</header>
				${this.localFormData.length > 0
					? html`
							<div class="mediaList">
								${repeat(
									sortedMediaCollection,
									(media) => media.id,
									(media, index) => html`
										<div class="mediaItem">
											<arc-artwork-media-item
												.index=${index}
												.value=${media}
												?disabled=${!this.permittedToEdit}
												.artworkId=${this.artworkId}
												@value-change=${(e) =>
													this.handleItemChange(e.detail.index, e.detail.value)}
												@remove-file=${(e) => this.handleRemoveFile(e.detail.fileId)}
												@replace-file=${(e) =>
													this.handleReplaceFile(e.detail.fileId, e.detail.file)}
											></arc-artwork-media-item>
											<div class="arrowControls">
												<button
													?disabled=${index === 0 || !this.permittedToEdit}
													class="${index === 0 ? 'disabledArrow' : ''}"
													@click=${() => this.moveMedia(index, 'up')}
												>
													${arrowUp}
												</button>
												<button
													?disabled=${index === this.localFormData.length - 1 || !this.permittedToEdit}
													class="${index === this.localFormData.length - 1 ? 'disabledArrow' : ''}"
													@click=${() => this.moveMedia(index, 'down')}
												>
													${arrowDown}
												</button>
											</div>
										</div>
									`
								)}
							</div>
					  `
					: html`<p>${msg('No media available for this artwork.')}</p>`}
			</div>
		`;
	}
}
