export function trimObject(obj) {
	const trimmedObject = {};
	// copy all properties from object, when not empty
	for (const key in obj) {
		if (obj[key]) {
			trimmedObject[key] = obj[key];
		}
	}
	return trimmedObject;
}
