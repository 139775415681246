import {connect} from 'pwa-helpers';
import {LitElement, html} from 'lit';
import {customElement} from 'lit/decorators/custom-element.js';
import {classMap} from 'lit/directives/class-map.js';
import {when} from 'lit/directives/when.js';

import {msg} from '@lit/localize';
import {navigator, router} from 'lit-element-router';
import deepEqual from 'deep-equal';

import store from '../../store';
import getRoutes from '../../routes';
import style from '../../../../scss/artistShare.scss';
import formPageStyle from '../../../../scss/formPage.scss';
import linkstyle from '../../../../scss/link.scss';
import loadingSpinnerStyle from '../../../../scss/loadingSpinner.scss';

import {
	selectProfileById,
	getProfile, updateProfileSettings, updateProfile
} from '../../slices/profileSlice';
import {profileDisplayName} from '../../util/profileDisplayName';
import {deepClone} from '../../util/deepClone';
import {selectCurrentAccountId, selectProfileDefaultLanguage, selectCurrentProfileId} from '../../selectors';
import {inviteUser} from '../../slices/accountSlice';
import {showMessage} from '../../slices/statusSlice';

@customElement('arc-artist-share')
export class ArtistShare extends connect(store)(router(navigator(LitElement))) {
	static styles = [style, formPageStyle, linkstyle, loadingSpinnerStyle];

	static properties = {
		localFormData: {type: Object, hasChanged: (n, o) => !deepEqual(n, o)},
		errors: {type: Object,  hasChanged: (n, o) => !deepEqual(n, o)},
		inviteUserEmail: {type: String},
		edit: {type: Boolean}
	};

	constructor() {
		super();
		this.localFormData = null;
		this.inviteUserEmail = '';
		this.errors = null;
		this.isLoading = false;

		store.subscribe(() => this.requestUpdate());
	}

	stateChanged(state) {
		this.state = state;
		this.status = state.status;
		this.errors = state.profile.error;
		this.isLoading = state.profile.loading === 'loading';
		this.currentAccountId = selectCurrentAccountId(state);
	}

	static get routes() {
		return getRoutes();
	}

	async router(route, params, query, data) {
		this.params = params;

		this.edit = true; // params.mode === 'edit';
		if (route === 'artist-share' && this.profileId !== params.id) {
			this.profileId = params.id;
			await store.dispatch(getProfile({id: this.profileId})); // fetch profile from backend
			// clone data for local editing (component state)
			this.localFormData = selectProfileById(this.state, this.profileId);
		}
		this.requestUpdate();
	}

	handleValueChange(fieldData, value) {
		const section = fieldData.section;
		const field = fieldData.field;

		// update localFormData with new value in section and field
		const newData = deepClone(this.localFormData); // clone current date

		// checkbox: convert string to number if it is a integer
		if (fieldData.type === 'checkbox' && typeof value === 'string' && value.match(/^\d+$/)) {
			value = parseInt(value, 10);
		}

		if (section) {
			if (newData[section]?.[field]) {
				newData[section][field] = value; // update field
			} else {
				newData[section] = {...newData[section], [field]: value}; // create new field
			}
		} else {
			newData[field] = value; // update field
		}
		this.localFormData = newData; // assign (updates reference, triggers update)
	}

	connectedCallback() {
		super.connectedCallback();
	}

	disconnectedCallback() {
		super.disconnectedCallback();
	}

	formHasChanged() {
		// check if local form data is different
		const newData = deepClone(this.localFormData);
		const oldData = deepClone(selectProfileById(this.state, this.profileId));
		delete newData.modified;
		delete oldData.modified;
		return !deepEqual(newData, oldData);
	}

	// handle Inputs
	onEmailInput(input) {
		this.inviteUserEmail = input;
	}
	async onInviteUser() {
		// send email to API
		await store.dispatch(inviteUser({
			email: this.inviteUserEmail,
			profileId: this.profileId
		}));
		this.inviteUserEmail = '';
	}

	render() {
		//loading message before the form loads with data
		if (this.isLoading || !this.localFormData) {
			return html`
				<div class="loading-overlay">
					<div class="spinner"></div>
				</div>
			`;
		}
		return html`
			<div class="formPage">
				<arc-toolbar class="toolbar">
					<div slot="left">
						<arc-breadcrumbs>
							<arc-routerlink route="artist">${msg('Profile')}: ${profileDisplayName(this.localFormData)}</arc-routerlink>
							<span>${msg('Collaboration')}</span>
						</arc-breadcrumbs>
					</div>
					<div slot="right">
					</div>
				</arc-toolbar>
				<header>
					<h2 class="pageTitle">${msg('Collaboration')}</h2>
				</header>


				${when(this.localFormData?.ownerId === this.currentAccountId, () => html`
				<section>
					<div class="leftColumn">
						<h2 class="sectionTitle">${msg('Invite Editor')}</h2>
						<p>${msg('invite_user_explanation')}</p>
						<div class="inviteUserForm">
							<arc-text-input
								title="${msg('eMail')}"
								placeholder="${msg('Enter EMail to sent invitation')}"
								.value=''
								@value-change=${(ev) => this.onEmailInput(ev.detail.value)}
							></arc-text-input>
							<arc-button
								title="${msg('Invite User')}"
								type="primary"
								.value="${this.inviteUserEmail}"
								.additionalInfo="${false}"
								?disabled="${!this.inviteUserEmail || this.inviteUserEmail === ''}"
								class="inviteUserButton"
								@click=${this.onInviteUser}
							></arc-button>
						</div>
					</div>
					<div class="rightColumn">
					</div>
				</section>

				<section>
					<div class="fullColumn">
						<h2>${msg('Account with permissions for this profile')}</h2>
						<p>${msg('description_allowed_accounts')}</p>
						<arc-allowed-list .showSearch="${false}" .showFilter="${false}"></arc-allowed-list>
					</div>
				</section>

			</div>
				`)}

    `;
	}
}
