import {connect} from 'pwa-helpers';
import {LitElement, html} from 'lit';
import {navigator, router} from 'lit-element-router';
import {customElement} from 'lit/decorators.js';
import {repeat} from 'lit/directives/repeat.js';
import {msg} from '@lit/localize';
import store from '../../store';
import {fetchArtworks, selectAllArtwork} from '../../slices/artworkSlice';
import {
	selectArtworkQuery,
	selectProfileDefaultLanguage,
	selectLanguage
} from '../../selectors';
import style from '../../../../scss/artworks.scss';
import getRoutes from '../../routes';
import down from '../../icons/down';
import up from '../../icons/up';
import formPageStyle from '../../../../scss/formPage.scss';

@customElement('arc-artworks-bin')
export class ArtworskBin extends connect(store)(router(navigator(LitElement))) {
	static styles = [formPageStyle, style];

	static properties = {
		artworks: {type: Array}
	};

	constructor() {
		super();
		this.artworks = [];
		store.subscribe(() => this.requestUpdate());
	}

	stateChanged(state) {
		this.state = state;
		this.status = state.status;
		this.lang = selectLanguage(state);
		this.artworkQuery = selectArtworkQuery(state);
		this.artworks = selectAllArtwork(state).filter((artwork) => artwork.isDeleted);
		this.totalPages = state.artwork.artworkPagination.pagesAmount;
		this.currentLanguage = selectProfileDefaultLanguage(state);
		this.requestUpdate();
	}

	static get routes() {
		return getRoutes();
	}

	router(route, params, query, data) {
		this.params = params;
		this.query = query;
		this.data = data;
		this.profileId = params.id;
	}

	connectedCallback() {
		super.connectedCallback();
	}

	getOrderQuery(orderBy) {
		const orderByDirection = this.query?.orderByDirection || 'asc';
		const newQuery = {
			...this.query,
			orderBy: orderBy,
			orderByDirection: orderByDirection === 'desc' ? 'asc' : 'desc'
		};
		return newQuery;
	}

	getOrderDirectionIcon(orderBy) {
		if (this.query && this.query.orderBy === orderBy) {
			return this.query.orderByDirection === 'asc' ? down : up;
		} else {
			return '';
		}
	}

	render() {
		return html`
			<div class="formPage artworks bin">
				<arc-toolbar class="toolbar">
					<div class="leftSlot" slot="left">
						<arc-breadcrumbs>
							<arc-routerlink route="artworks">${msg('Artworks')}</arc-routerlink>
						</arc-breadcrumbs>
					</div>
					<div class="rightSlot" slot="right"></div>
				</arc-toolbar>

				<header>
					<h2 class="pageTitle">${msg('Deleted Artworks')}</h2>
					<arc-content-language-switch></arc-content-language-switch>
				</header>

				<arc-artwork-list class="artworkList" .noFilters="${true}"></arc-artwork-list>
			</div>
		`;
	}
}
