/**
 * Check if a value is a function
 *
 * @export
 * @param {*} value the value to be checked
 * @returns {boolean} true when it is a function
 */
export function isFunction(value) {
	return typeof value === 'function';
}

/**
 * check if a value is a number
 *
 * @export
 * @param {*} value the value to be checked
 * @returns {boolean} true when it is a number
 */
export function isNumber(value) {
	return typeof value === 'number' || Object.prototype.toString.call(value) === '[object Number]';
}

/**
 * check if a value is a string
 *
 * @export
 * @param {*} value the value to be checked
 * @returns {boolean} true when it is a string
 */
export function isString(value) {
	return typeof value === 'string' || Object.prototype.toString.call(value) === '[object String]';
}

/**
 * check if a value is an array
 *
 * @export
 * @param {*} value the value to be checked
 * @returns {boolean} true when it is an array
 */
export function isArray(value) {
	return Array.isArray(value);
}

/**
 * check if a value is an object
 *
 * @export
 * @param {*} value the value to be checked
 * @returns {boolean} true when it is an object
 */
export function isObject(value) {
	const type = typeof value;
	return type === 'function' || (type === 'object' && !!value);
}

/**
 * check if a value is numeric, which means in this case a number or a string that is a valid representation of a number
 *
 * @see {@link http://stackoverflow.com/questions/18082/validate-decimal-numbers-in-javascript-isnumeric}
 * @export
 * @param {*} value the value to be checked
 * @returns {boolean} true when it is numeric
 */
export function isNumeric(value) {
	return !isArray(value) && value - parseFloat(value) + 1 >= 0;
}

/**
 * check if a value seems a plain JS object, which means in this case a simple object created with
 * {} that contains only key-value pairs. There could be corner cases in which this could fail due
 * to the intrinsic dynamic of javascript prototype based object model, so the function onlu aims to
 * identify most common scenarios.
 *
 * @export
 * @param {*} value the value to be checked
 * @returns {boolean} true when it seems a plain object
 */
export function seemsPlainObject(value) {
	return isObject(value) && !(value instanceof Function) && value.constructor === Object;
}
