import {connect} from 'pwa-helpers';
import {LitElement, html} from 'lit';
import {customElement} from 'lit/decorators/custom-element.js';
import {msg, str} from '@lit/localize';

import {navigator, router} from 'lit-element-router';
import style from '../../../../scss/seriesListItem.scss';
import store from '../../store';
import {selectProfileDefaultLanguage, selectLanguage} from '../../selectors';
import {deleteSeries, selectAllSerie} from '../../slices/artworkSlice';
import {routeUrl} from '../../util/routeUrl';
import {navigateTo, openDialog} from '../../slices/statusSlice';
import {getValueForLanguage} from '../../util/getValueForLanguage';
import getRoutes from '../../routes';

@customElement('arc-series-list-item')
export class SeriesListItem extends connect(store)(router(navigator(LitElement))) {
	static styles = [style];
	static properties = {
		serie: {type: Array},
	};

	constructor() {
		super();
		this.serie = [];
		store.subscribe(() => this.requestUpdate());
	}

	stateChanged(state) {
		this.state = state;
		this.lang = selectLanguage(state);
		this.currentLanguage = selectProfileDefaultLanguage(state);
	}
	static get routes() {
		return getRoutes();
	}
	router(route, params, query, data) {
		this.route = route;
		this.params = params;
	}

	handleEditSeries() {
		store.dispatch(navigateTo({route: 'series-detail', params: {...this.params, ...{seriesId: this.serie.id, mode: 'edit'}}}));
	}

	async handleDeleteSeries() {
		const title = getValueForLanguage(this.serie.title, this.lang, true);
		store.dispatch(
			openDialog({
				title: msg('Remove Series'),
				warningMessage: msg(
					str`Removing Series "${title}" will immediately delete it. The artworks in this series remain in the artwork list.`
				),
				labelConfirm: msg('Remove Series'),
				labelCancel: msg('Cancel'),
			})
		);
		this._handleConfirm = async () => {
			await store.dispatch( deleteSeries({id: this.serie.id}));
			this.series = selectAllSerie(this.state);
			window.removeEventListener('confirm', this._handleConfirm);
		};
		window.addEventListener('confirm', this._handleConfirm);
	}


	render() {
		return html`

			<div class="seriesItem" @click="${this.handleEditSeries}">
				<div class="seriesListItemCell">
					<arc-routerlink
						noUnderline
						class="seriesDetail"
						route="series-detail"
						.params=${{
							seriesId: this.serie.id
						}}>
						${getValueForLanguage(this.serie.title, this.lang, true)}
					</arc-routerlink>
				</div>
				<div class="seriesListItemCell">
					${this.serie.artworksAmount}
				</div>
			</div>
			<div class="seriesListButtons">
				<arc-dropdown-menu>
					<arc-button
						type="deleteButton"
						title="${msg('Remove')}"
						@click=${this.handleDeleteSeries}>
						${msg('Remove')}
					</arc-button>
				</arc-dropdown-menu>
			</div>
    `;
	}
}
