import {connect} from 'pwa-helpers';
import {LitElement, html} from 'lit';
import {customElement} from 'lit/decorators/custom-element.js';
import {msg} from '@lit/localize';
import deepEqual from 'deep-equal';

import {navigator, router} from 'lit-element-router';
import style from '../../../../scss/seriesList.scss';
import store from '../../store';
import {selectLanguage} from '../../selectors';
import {fetchSeries, selectAllSerie} from '../../slices/artworkSlice';
import getRoutes from '../../routes';
import {navigateTo} from '../../slices/statusSlice';
import formPageStyle from '../../../../scss/formPage.scss';

@customElement('arc-series-list')
export class SeriesList extends connect(store)(router(navigator(LitElement))) {
	static styles = [formPageStyle, style];
	static properties = {
		series: {type: Array},
		profileId: {type: String},

		updateNeeded: {type: Boolean}
	};

	constructor() {
		super();
		this.series = [];
		this.profileId = '';
		this.updateNeeded = false;
		store.subscribe(() => this.requestUpdate());
	}

	stateChanged(state) {
		this.state = state;
		this.status = state.status;
		this.lang = selectLanguage(state);
		this.series = selectAllSerie(state);
	}

	update(changedProperties) {
		super.update(changedProperties);

		// fetch new serials list if needed, but only once
		if (this.updateNeeded) {
			this.updateNeeded = false;
			store.dispatch(fetchSeries({profileId: this.profileId}));
		}
	}

	static get routes() {
		return getRoutes();
	}

	router(route, params, query, data) {

		// check if we need to update the series list
		this.updateNeeded = route === 'series-list' &&
			(this.profileId !== params.id || !deepEqual(this.query, query));

		this.profileId = params.id;
		this.params = params;
		this.query = query;
	}

	handleAddSeries() {
		store.dispatch(navigateTo({route: 'series-create', params: this.params}));
	}

	render() {
		return html`
			<div class="formPage">

				<arc-toolbar class="toolbar">
					<div class="leftSlot" slot="left">
						<arc-breadcrumbs>
							<arc-routerlink route="artworks">${msg('Artworks')}</arc-routerlink>
							<span>${msg('Series')}</span>
						</arc-breadcrumbs>
					</div>
					<div class="rightSlot" slot="right">
						<arc-button
							title="${msg('Add Series')}"
							type="primary"
							.additionalInfo="${false}"
							@click=${this.handleAddSeries}
						></arc-button>
					</div>
				</arc-toolbar>

				<header>
					<h2 class="pageTitle">${msg('Series')}</h2>
					<arc-content-language-switch></arc-content-language-switch>
				</header>

				${this.series.length > 0
					? html`
						<div class="seriesList">
							<div class="seriesListHeader">
								<div>${msg('Title')}</div>
								<div>${msg('Artworks')}</div>
								<div></div>
							</div>

							${this.series.map(
								(serie) => html`
									<arc-series-list-item .serie=${serie}></arc-series-list-item>
								`)}

						</div>` : html`<p>${msg('No series available')}</p>`
				}
			</div>
		`;
	}
}
