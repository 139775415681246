export default function() {

	const header = 'arc-csrf-token';
	let csrf = null;

	// the actual middleware
	return function csrfMiddleware(req) {
		// optionally set the csrf token
		if (csrf) req.set(header, csrf);

		// retain reference to old callback
		const callback = req.callback;

		// patch callback to capture token
		req.callback = function(err, res) {
			if (err || !res) return callback.call(req, err, res);

			csrf = res.headers[header];
			// console.log('csrf get', csrf);
			// invoke original callback ref
			return callback.call(req, err, res);
		};
	};

}
