import {connect} from 'pwa-helpers';
import debounce from 'debounce';
import deepEqual from 'deep-equal';
import {LitElement, html} from 'lit';
import {navigator, router} from 'lit-element-router';
import {customElement} from 'lit/decorators/custom-element.js';
import {when} from 'lit/directives/when.js';

import {msg} from '@lit/localize';
import store from '../../store';
import style from '../../../../scss/accountListFilter.scss';
import {
	initialAccountsQuery,
	setAccountsQuery
} from '../../slices/accountSlice';

import {
	selectAccountsQuery,
	selectProfileDefaultLanguage,
	selectLanguage
} from '../../selectors';
import {routeUrl} from '../../util/routeUrl';
import getRoutes from '../../routes';
import {trimObject} from '../../util/trimObject';
import {isPermitted} from '../../util/isPermitted';
import {navigateTo} from '../../slices/statusSlice';

@customElement('arc-account-list-filter')
export class AccountListFilter extends connect(store)(router(navigator(LitElement))) {
	static styles = [style];

	roleOptions = [
		{
			id: 'user',
			name: [
				{lang: 'en', value: 'User'},
				{lang: 'de', value: 'Benutzer'}
			]
		},
		{
			id: 'admin',
			name: [
				{lang: 'en', value: 'Admin'},
				{lang: 'de', value: 'Admin'}
			]
		},
		{
			id: 'operator',
			name: [
				{lang: 'en', value: 'Operator'},
				{lang: 'de', value: 'Operator'}
			]
		}
	];
	flagsOptions = [
		{
			id: '-1',
			name: [
				{lang: 'en', value: 'all'},
				{lang: 'de', value: 'Alle'}
			]
		},
		{
			id: '1',
			name: [
				{lang: 'en', value: 'Ja'},
				{lang: 'de', value: 'Yes'}
			]
		},
		{
			id: '0',
			name: [
				{lang: 'en', value: 'No'},
				{lang: 'de', value: 'Nein'}
			]
		}
	];

	static properties = {
		searchText: {type: String},
		showSearch: {type: Boolean},
		showFilter: {type: Boolean},
	};

	constructor() {
		super();
		this.currentLanguage = 'en';
		this.searchText = '';
		this.showSearch = true;
		this.showFilter = true;
		store.subscribe(() => this.requestUpdate());
	}

	stateChanged(state) {
		this.state = state;
		this.status = state.status;
		this.lang = selectLanguage(state);
		this.accountsQuery = selectAccountsQuery(state);
		this.currentLanguage = selectProfileDefaultLanguage(state);

		this.roleOptions = [];
		if (isPermitted(['account/viewUser'], null, state)) {
			this.roleOptions.push({
				id: 'user',
				name: [
					{lang: 'en', value: 'User'},
					{lang: 'de', value: 'Benutzer'}
				]
			});
		}
		if (isPermitted(['account/viewAdmin'], null, state)) {
			this.roleOptions.push({
				id: 'admin',
				name: [
					{lang: 'en', value: 'Admin'},
					{lang: 'de', value: 'Admin'}
				]
			});
		}
		if (isPermitted(['account/viewOperator'], null, state)) {
			this.roleOptions.push({
				id: 'operator',
				name: [
					{lang: 'en', value: 'Operator'},
					{lang: 'de', value: 'Operator'}
				]
			});
		}

		if (this.updateNeeded) {
			this.updateNeeded = false;
			if (Object.keys(this.query).length === 0) {
				// if query is empty, navigate to initial query
				this.navigateToQuery(initialAccountsQuery);
			} else {
				// if not, set the query in the store
				store.dispatch(setAccountsQuery(this.query));
			}
		}

	}

	static get routes() {
		return getRoutes();
	}

	router(route, params, query, data) {
		this.params = params;
		this.query = query;
		this.data = data;
		this.profileId = params.id;

		// do not update dispatch immediately
		this.updateNeeded = route === 'admin-accounts' && !deepEqual(this.query, query);

	}

	connectedCallback() {
		super.connectedCallback();
	}

	disconnectedCallback() {
		super.disconnectedCallback();
	}


	resetFilters() {
		this.navigateToQuery(initialAccountsQuery);
	}

	handleSearchChange(value) {
		this.navigateToQuery({...initialAccountsQuery, ...this.query, ...{search: value.trim().toLowerCase()}});
	}

	handleRoleChange(value) {
		this.navigateToQuery({...initialAccountsQuery, ...this.query, ...{role: value}});
	}

	handleTestChange(value) {
		this.navigateToQuery({...initialAccountsQuery, ...this.query, ...{isTest: value}});
	}

	handleHasLoggedInOnceChange(value) {
		this.navigateToQuery({...initialAccountsQuery, ...this.query, ...{hasLoggedInOnce: value}});
	}

	handleIsEnabledChange(value) {
		this.navigateToQuery({...initialAccountsQuery, ...this.query, ...{isEnabled: value}});
	}

	navigateToQuery(query) {
		store.dispatch(navigateTo({route: 'admin-accounts', params: this.params, query: trimObject(query)}));
		store.dispatch(setAccountsQuery(trimObject(query)));
	}

	render() {
		return html`
			<div class="accountListFilter">
				${when(this.showSearch,
					() => html`
						<div class="searchAndFilters">
							<div class="search">
								<arc-text-input
									class="formInput search"
									.title="${msg('Search')}"
									.changeIndication=${false}
									.value=${this.accountsQuery.search}
									@value-change=${debounce((e) => this.handleSearchChange(e.detail.value), 500)}
								></arc-text-input>
							</div>
							<arc-button
								title="${msg('Reset Filters')}"
								type="secondary"
								@click="${this.resetFilters}"
							></arc-button>
						</div>
					`)}

				${when(this.showFilter,
					() => html`
						<div class="filterContainer">
							<div class="filterItem">
								<arc-multiselect
									class="formInput role"
									.singleSelect=${true}
									.title="${msg('Role')}"
									.edit=${true}
									.options=${this.roleOptions}
									.value=${[this.accountsQuery.role]}
									@value-change=${(e) => this.handleRoleChange(e.detail.value[0])}
									.labelField=${'name'}
									.valueField=${'id'}
									.showSelectedCount=${false}
									.showCounters=${false}
								></arc-multiselect>
							</div>
							<div class="filterItem">
								<arc-multiselect
									class="formInput isEnabled"
									.singleSelect=${true}
									.title="${msg('Enabled')}"
									.edit=${true}
									.options=${this.flagsOptions}
									.value=${[this.accountsQuery.isEnabled]}
									@value-change=${(e) => this.handleIsEnabledChange(e.detail.value[0])}
									.labelField=${'name'}
									.valueField=${'id'}
									.showSelectedCount=${false}
									.showCounters=${false}
								></arc-multiselect>
							</div>
							<div class="filterItem">
								<arc-multiselect
									class="formInput isEnabled"
									.singleSelect=${true}
									.title="${msg('Test')}"
									.edit=${true}
									.options=${this.flagsOptions}
									.value=${[this.accountsQuery.isTest]}
									@value-change=${(e) => this.handleTestChange(e.detail.value[0])}
									.labelField=${'name'}
									.valueField=${'id'}
									.showSelectedCount=${false}
									.showCounters=${false}
								></arc-multiselect>
							</div>
							<div class="filterItem">
								<arc-multiselect
									class="formInput hasLoggedInOnce"
									.singleSelect=${true}
									.title="${msg('Has Logged In Once')}"
									.edit=${true}
									.options=${this.flagsOptions}
									.value=${[this.accountsQuery.hasLoggedInOnce]}
									@value-change=${(e) => this.handleHasLoggedInOnceChange(e.detail.value[0])}
									.labelField=${'name'}
									.valueField=${'id'}
									.showSelectedCount=${false}
									.showCounters=${false}
								></arc-multiselect>
							</div>
						</div>
					`)}

			</div>
		`;
	}
}
