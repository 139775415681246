import {html, css, LitElement} from 'lit';
import {customElement} from 'lit/decorators/custom-element.js';
import {connect} from 'pwa-helpers';
import store from '../store';
import style from '../../../scss/toolbar.scss';

@customElement('arc-toolbar')
export class Toolbar extends connect(store)(LitElement) {
	static styles = [style];

	render() {
		return html`
			<div class="left">
				<slot name="left"></slot>
			</div>
			<div class="right">
				<slot name="right"></slot>
			</div>
		`;
	}
}
