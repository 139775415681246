import {connect} from 'pwa-helpers';
import {LitElement, html} from 'lit';
import {customElement} from 'lit/decorators/custom-element.js';
import {repeat} from 'lit/directives/repeat.js';
import {when} from 'lit/directives/when.js';
import {msg} from '@lit/localize';
import store from '../../store';

import style from '../../../../scss/profile/profileList.scss';
import tableStyle from '../../../../scss/table.scss';

import {
	addProfile,
	fetchProfiles,
	selectAllProfiles,
	setCurrentProfileId
} from '../../slices/profileSlice';
import {profileDisplayName} from '../../util/profileDisplayName';
import plus from '../../icons/plus';
import {selectCurrentAccountId, selectCurrentProfileId, selectLanguage} from '../../selectors';
import {showMessage} from '../../slices/statusSlice';

@customElement('arc-profile-list')
export class ProfileList extends connect(store)(LitElement) {

	static styles = [tableStyle, style];

	constructor() {
		super();
		store.subscribe(() => this.requestUpdate());
	}

	stateChanged(state) {
		this.status = state.status;
		this.lang = selectLanguage(state);
		this.profiles = selectAllProfiles(state);
		this.profileId = selectCurrentProfileId(state);
		this.accountId = selectCurrentAccountId(state);
	}

	connectedCallback() {
		super.connectedCallback();
		store.dispatch(fetchProfiles({isOwned: -1}));
	}

	disconnectedCallback() {
		super.disconnectedCallback();
	}

	async handleAddProfile() {
		await store.dispatch(addProfile({name: 'New Profile'}));
	}
	handleSwitchProfile(id) {
		store.dispatch(setCurrentProfileId(id));
		store.dispatch(
			showMessage({
				message: msg('Profile switched'),
				messageType: 'success'
			})
		);
	}

	profilePermissionDisplay(permissions) {
		if (permissions?.includes('profile/edit')) {
			return msg('edit');
		}
		if (permissions?.includes('profile/view')) {
			return msg('view');
		}
		return '';
	}
	artworkPermissionDisplay(permissions) {
		if (permissions?.includes('artwork/edit')) {
			return msg('edit');
		}
		if (permissions?.includes('artwork/view')) {
			return msg('view');
		}
		return '';
	}

	render() {
		const state = store.getState();
		return html`

		<div class="profiles">

			<table class="profiles-table">
				<tbody>
				<tr>
					<th>${msg('Id')}</th>
					<th>${msg('Profile Name')}</th>
					<th>${msg('Owner')}</th>
					<th>${msg('Last Modified on')}</th>
					<th>${msg('Profile Permissions')}</th>
					<th>${msg('Artwork Permissions')}</th>
					<th></th>
				</tr>

				${repeat(
					this.profiles,
					(profile) => profile.id,
					(profile) => html`
						<tr>
							<td>
								${profile.id}
							</td>
							<td>
								${profileDisplayName(profile)}
								${when(this.accountId === profile.ownerId, () => html`(${msg('Your Profile')})`)}
							</td>
							<td>
								${profile.ownerName}
							</td>
							<td>
								${profile.modified}
							</td>
							<td>
								${this.profilePermissionDisplay(profile.permissions)}
							</td>
							<td>
								${this.artworkPermissionDisplay(profile.permissions)}
							</td>
							<td>
								<arc-button
									class="switchProfileButton"
									type="primary"
									.disabled=${this.profileId + '' === profile.id + ''}
									@click=${() => this.handleSwitchProfile(profile.id)}
									title="${msg('Switch to Profile')}">
								</arc-button>
							</td>
						</tr>
					`)}

				</tbody>
			</table>
			<!--
			<arc-button
				title="${msg('Add Profile')}"
				type="primary"
				.additionalInfo="${false}"
				class="addProfileButton"
				showIcon="true"
				@click=${this.handleAddProfile}
			>
				${plus}
			</arc-button>
			-->
		</div>
		`;
	}
}
