import {createSlice, createAsyncThunk, createEntityAdapter} from '@reduxjs/toolkit';
import agent from '../../arc/agent/agent';

export const fileAdapter = createEntityAdapter();
const fileInitialState = {
	status: 'idle',
	// fullfilled by entityAdapter
	ids: [],
	entities: [],
	error: {}
};

export const fetchFile = createAsyncThunk(
	'file/fetchFile',
	(options, {getState}) =>
		agent
			.get(`${getState().status.apiUrl}/file/get`)
			.query({id: options.id})
			.set('Accept', 'application/json')
			.then((response) => response.body)

			.catch((error) => error)
);

export const fileSlice = createSlice({
	name: 'account',
	initialState: fileInitialState,

	// standard actions: for example update data
	// export actions at end of file
	reducers: {},

	// async actions: server api calls workflows
	extraReducers: (builder) => {
		builder.addCase(fetchFile.pending, (state, action) => {
			state.loading = 'loading';
			state.error = null;
		});
		builder.addCase(fetchFile.rejected, (state, action) => {
			state.loading = 'idle';
			state.error = action.payload.response.body; // save error info for display in frontend
		});
		builder.addCase(fetchFile.fulfilled, (state, action) => {
			state.loading = 'idle';
			state.error = null;
			fileAdapter.upsertOne(state, action.payload.item);
		});
	}
});


export const {} = fileSlice.actions;

export const {
	selectById: selectFileById,
	selectIds: selectFileIds,
	selectEntities: selectFileEntities,
	selectAll: selectAllFiles,
	selectTotal: selectTotalFiles
} = fileAdapter.getSelectors((state) => state.file);

export default fileSlice.reducer;
