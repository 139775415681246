import {createSlice} from '@reduxjs/toolkit';
import {msg, str} from '@lit/localize';
import {routeUrl} from '../util/routeUrl';
import {artworkSlice} from './artworkSlice';
import store from '../store';

const statusInitialState = {
	status: 'idle',

	// ui state
	isHeaderDropdownOpened: false,
	isAdminDropdownOpened: false,
	isProfileDropdownOpened: false,
	isArtworksDropdownOpened: false,

	// base urls
	host: null,
	baseUrl: null,
	apiUrl: null,

	// route
	route: {
		route: null,
		params: null,
		query: null
	},

	// ui language
	lang: '',

	// content language
	currentContentLanguage: 'de',
	availableContentLanguages: ['de', 'en'],

	dialog: {
		open: false,
		message: '',
		title: '',
		warningMessage: '',
		artworkTitle: '',
	},

	error: {},
	message: null,
	messageType: null,
	showMessage: false,

	formChanged: false
};

function updateRoute(state, action) {
	/*
	state.route = {
		route: action.payload.route,
		params: action.payload.params,
		query: action.payload.query
	};
	state.lang = action.payload.params?.language ?? state.lang;
	*/
	document.removeEventListener('confirm', () => updateRoute(state, action));
	window.history.pushState(
		{},
		null,
		routeUrl(
			action.payload.route,
			action.payload.params,
			action.payload.query
		)
	);
	window.dispatchEvent(new CustomEvent('route'));
}

export const statusSlice = createSlice({
	name: 'profile',
	initialState: statusInitialState,
	reducers: {
		openDialog: (state, action) => {
			state.dialog.message = action.payload.message;
			state.dialog.title = action.payload.title || '';
			state.dialog.warningMessage = action.payload.warningMessage || '';
			state.dialog.artworkTitle = action.payload.artworkTitle || '';
			state.dialog.labelConfirm = action.payload.labelConfirm || '';
			state.dialog.labelCancel = action.payload.labelCancel || '';
			document.dispatchEvent(new CustomEvent('open-dialog'));
		},
		closeDialog: (state) => {
			state.dialog.message = '';
			state.dialog.title = '';
			state.dialog.warningMessage = '';
			state.dialog.artworkTitle = '';
		},
		navigateTo: (state, action) => {
			// close header menus
			state.isAccountDropdownOpened = false;
			state.isAdminDropdownOpened = false;
			state.isProfileDropdownOpened = false;
			state.isArtworksDropdownOpened = false;

			if (!state.formChanged) {
				updateRoute(state, action);
			} else {
				state.dialog.message = msg('The Form has unsaved changes. Do you really want to leave?');
				state.dialog.warningMessage = '';
				state.dialog.title = msg('Unsaved Changes');
				state.dialog.labelConfirm = msg('Discard Changes');
				state.dialog.labelCancel = msg('Stay on Form');
				document.addEventListener('confirm', () => updateRoute(state, action));
				document.dispatchEvent(new CustomEvent('open-dialog'));
			}
		},
		setStatus: (state, action) => {
			state = Object.assign(state, action.payload);
		},
		setLang: (state, action) => {
			state.lang = action.payload.lang;
		},
		setCurrentContentLanguage: (state, action) => {
			state.currentContentLanguage = action.payload;
		},
		setFormChanged: (state, action) => {
			state.formChanged = action.payload;
		},
		setAccountDropdownState: (state, action) => {
			state.isAccountDropdownOpened = action.payload;
			state.isAdminDropdownOpened = false;
			state.isProfileDropdownOpened = false;
			state.isArtworksDropdownOpened = false;
		},
		setAdminDropdownState: (state, action) => {
			state.isAdminDropdownOpened = action.payload;
			state.isAccountDropdownOpened = false;
			state.isProfileDropdownOpened = false;
			state.isArtworksDropdownOpened = false;
		},
		setProfileDropdownState: (state, action) => {
			state.isProfileDropdownOpened = action.payload;
			state.isAccountDropdownOpened = false;
			state.isAdminDropdownOpened = false;
			state.isArtworksDropdownOpened = false;
		},
		setArtworksDropdownState: (state, action) => {
			state.isArtworksDropdownOpened = action.payload;
			state.isAccountDropdownOpened = false;
			state.isAdminDropdownOpened = false;
			state.isProfileDropdownOpened = false;
		},
		showMessage: (state, action) => {
			state.message = action.payload.message;
			state.messageType = action.payload.messageType;
			state.showMessage = true;
		},
		hideMessage: (state) => {
			state.showMessage = false;
			state.message = null;
			state.messageType = null;
		}
	}
});

export const {
	openDialog,
	closeDialog,
	setFormChanged,
	setCurrentContentLanguage,
	navigateTo,
	setStatus,
	setLang,
	setAccountDropdownState,
	setAdminDropdownState,
	setProfileDropdownState,
	setArtworksDropdownState,
	showMessage,
	hideMessage
} = statusSlice.actions;

export default statusSlice.reducer;
