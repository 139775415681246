import {setArtworkSeries} from '../slices/artworkSlice';

export const seriesMiddleware = (store) => (next) => async (action) => {
	const result = next(action);

	if (action.type ===  'series/create/fulfilled') {
		//const seriesId = action.payload.item.id;
		//store.dispatch(setArtworkSeries({artworkId: artworkId, seriesId: seriesId}));
	}

	return result;
};
