import {html, css, LitElement} from 'lit';
import {customElement} from 'lit/decorators/custom-element.js';
import deepEqual from 'deep-equal';

import {msg} from '@lit/localize';
import style from '../../../../../scss/space.scss';
import {FormElement} from '../form/formElement';

@customElement('arc-space')
export class Space extends FormElement {
	static properties = {
		value: {type: Object, hasChanged: (n, o) => !deepEqual(n, o)},
		errors: {type: Array, hasChanged: (n, o) => !deepEqual(n, o)},

		index: {type: Number},
		type: {type: String},
	};

	static styles = [...FormElement.styles, style];

	initialValue = {
		type: 'space',
		title: [],
		depth: {
			unit: 'cm',
			value: '',
		},
		width: {
			unit: 'cm',
			value: '',
		},
		height: {
			unit: 'cm',
			value: '',
		},
	};

	units = [
		{id: 'mm', name: 'mm'},
		{id: 'cm', name: 'cm'},
		{id: 'm', name: 'm'},
		{id: 'in', name: 'in'},
		{id: 'ft', name: 'ft'},
	];

	titleChange(value) {
		this.inputValue.title = value;
		this.dispatchValueChange(this.inputValue);
	}
	inputWidthValue(value) {
		this.inputValue.width.value = value;
		this.dispatchValueChange(this.inputValue);
	}
	selectWidthUnit(value) {
		this.inputValue.width.unit = value;
		this.dispatchValueChange(this.inputValue);
	}
	inputHeightValue(value) {
		this.inputValue.height.value = value;
		this.dispatchValueChange(this.inputValue);
	}
	selectHeightUnit(value) {
		this.inputValue.height.unit = value;
		this.dispatchValueChange(this.inputValue);
	}
	inputDepthValue(value) {
		this.inputValue.depth.value = value;
		this.dispatchValueChange(this.inputValue);
	}
	selectDepthUnit(value) {
		this.inputValue.depth.unit = value;
		this.dispatchValueChange(this.inputValue);
	}

	render() {
		return html`
			<div class="space">
				<div class="dimensions">
					<div class="dimension">
						<div class="formContainer">
							<arc-text-input
								class="value"
								id="height"
								class="value height"
								.title="${msg('Height')}"
								?disabled="${this.disabled}"
								.multiline=${false}
								.value=${this.inputValue.height.value}
								.errors=${this.errors?.params?.height.params.value}
								.required=${false}
								@value-change=${(e) => this.inputHeightValue(e.detail.value)}
							></arc-text-input>
							<arc-select
								class="unit"
								.title=""
								?disabled="${this.disabled}"
								.value=${this.inputValue.height.unit}
								.errors=${this.errors?.params?.height.params.unit}
								.options=${this.units}
								.required=${false}
								@value-change=${(e) => this.selectHeightUnit(e.detail.value)}
							>
							</arc-select>
						</div>
					</div>
					<div class="dimension">
						<div class="formContainer">
							<arc-text-input
								class="value"
								id="width"
								class="value width"
								.title="${msg('Width')}"
								?disabled="${this.disabled}"
								.multiline=${false}
								.value=${this.inputValue.width.value}
								.errors=${this.errors?.params?.width?.params?.value?.errors || []}
								.required=${false}
								@value-change=${(e) => this.inputWidthValue(e.detail.value)}
							></arc-text-input>
							<arc-select
								class="unit"
								.title=""
								?disabled="${this.disabled}"
								.value=${this.inputValue.width.unit}
								.errors=${this.errors?.params?.width?.params?.unit?.errors || []}
								.options=${this.units}
								.required=${false}
								@value-change=${(e) => this.selectWidthUnit(e.detail.value)}
							>
							</arc-select>
						</div>
					</div>
					<div class="dimension">
						<div class="formContainer">
							<arc-text-input
								class="value"
								id="depth"
								class="value depth"
								.title="${msg('Depth')}"
								?disabled="${this.disabled}"
								.multiline=${false}
								.value=${this.inputValue.depth.value}
								.errors=${this.errors?.params?.depth.params.value}
								.required=${false}
								@value-change=${(e) => this.inputDepthValue(e.detail.value)}
							></arc-text-input>
							<arc-select
								class="unit"
								.title=""
								?disabled="${this.disabled}"
								.value=${this.inputValue.depth.unit}
								.errors=${this.errors?.params?.depth.params.unit}
								.options=${this.units}
								.required=${false}
								@value-change=${(e) => this.selectDepthUnit(e.detail.value)}
							>
							</arc-select>
						</div>
					</div>
				</div>
				<arc-multilingual-input
					.title=${msg('Explanation')}
					.value=${this.inputValue.title}
					.errors=${this.errors?.params?.title}
					?disabled="${this.disabled}"
					.required=${false}
					.multiline=${false}
					@value-change=${(e) => this.titleChange(e.detail.value)}>
				</arc-multilingual-input>
			</div>
		`;
	}
}
