import {html, css, LitElement} from 'lit';
import {customElement} from 'lit/decorators.js';
import {connect} from 'pwa-helpers';

import style from '../../../../../scss/imageUpload.scss';
import store from '../../../store';
import {selectFileById} from '../../../slices/fileSlice';

@customElement('arc-image-upload')
class ImageUpload extends connect(store)(LitElement) {
	static properties = {
		addLabel: {type: String},
		removeLabel: {type: String},
		acceptedFormats: {type: String},
		uploadText: {type: String},
		fileId: {type: Number},
		errors: {type: Array},
		file: {type: Object},
		disabled: {type: Boolean},
	};

	constructor() {
		super();
		this.label = 'Upload Image';
		this.acceptedFormats = '.jpg, .png, .mov, .mp4, .pdf';
		this.uploadText = 'Choose a file';
		this.edit = true;
		this.noButtons = false;
		this.type = 'profile';
		this.fileId = null;
		this.errorMessage = '';
	}

	static styles = [style];

	stateChanged(state) {
		if (this.fileId) {
			this.file = selectFileById(state, this.fileId);
			this.imageUrl = this.file?.url;
		}
		if (this.value && this.value.url && this.value.ext === 'pdf') {
			this.previewUrl = this.value.url;
		}
	}

	connectedCallback() {
		super.connectedCallback();
		this.addEventListener('trigger-file-upload', this.triggerFileInput.bind(this));
	}

	disconnectedCallback() {
		this.removeEventListener('trigger-file-upload', this.triggerFileInput);

		super.disconnectedCallback();
	}

	addFile(event) {
		const file = event.target.files[0];
		if (file) {
			this.dispatchEvent(new CustomEvent('add-file', {detail: {file: file}}));
			const fileInput = this.shadowRoot.getElementById('fileInput');
			fileInput.value = null;
		}
	}

	removeFile(event) {
		if (this.fileId) {
			this.dispatchEvent(new CustomEvent('remove-file', {detail: {fileId: this.fileId}}));
		}
	}
	triggerFileInput() {
		const fileInput = this.shadowRoot.getElementById('fileInput');
		if (fileInput) {
			fileInput.click();
		}
	}

	render() {
		const hasFile = !!this.fileId;

		return html`
			<input
				id="fileInput"
				class="hiddenInput"
				type="file"
				accept="${this.acceptedFormats}"
				@change="${this.addFile}"
				?disabled="${this.disabled}"
			/>
			${hasFile
				? html`
						<arc-button
							title=${this.removeLabel}
							class=""
							type="secondary"
							?additionalInfo="${false}"
							@click="${this.removeFile}"
						></arc-button>
				  `
				: html`
						<arc-button
							title=${this.addLabel}
							type="secondary"
							?additionalInfo="${false}"
							@click="${this.triggerFileInput}"
							?disabled="${this.disabled}"
						></arc-button>
				  `}
		`;
	}
}
