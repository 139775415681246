import {createSlice, createAsyncThunk, createEntityAdapter} from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
import agent from '../agent/agent';
import store from '../store';
import {deepClone} from '../util/deepClone';

export const fetchProfiles = createAsyncThunk(
	'profile/fetchProfiles',
	(options, {getState}) =>
		agent
			.get(`${getState().status.apiUrl}/profile/collection`)
			.query(options)
			.set('Accept', 'application/json')
			.then((response) => response.body)
			.catch((error) => error)
);

export const addProfile = createAsyncThunk(
	'profile/addProfile',
	(options, {getState}) =>
		agent
			.post(`${getState().status.apiUrl}/profile/create`)
			.send(options)
			.set('Accept', 'application/json')
			.then((response) => response.body)
			.catch((error) => error)
);

export const getProfile = createAsyncThunk(
	'profile/get',
	(options, {getState}) => agent
		.get(`${getState().status.apiUrl}/profile/get`)
		.query(options)
		.set('Accept', 'application/json')
		.then((response) => response.body)
		.catch((error) => error)
);

export const updateProfile = createAsyncThunk(
	'profile/update',
	async (options, {getState, rejectWithValue}) => {
		try {
			const response = await agent
				.put(`${getState().status.apiUrl}/profile/update`)
				.send(options)
				.set('Accept', 'application/json');
			return response.body;
		} catch (error) {
			return rejectWithValue({
				message: error.response?.body?.errors[0]?.message || error.message,
				params: error.response?.body?.input?.params || {}
			});
		}
	}
);

export const updateProfileSettings = createAsyncThunk(
	'profile/updateSetting',
	async (options, {getState, rejectWithValue}) => {
		try {
			const response = await agent
				.put(`${getState().status.apiUrl}/profile/updateSettings`)
				.send(options)
				.set('Accept', 'application/json');
			return response.body;
		} catch (error) {
			return rejectWithValue({
				message: error.response?.body?.errors[0]?.message || error.message,
				params: error.response?.body?.input?.params || {}
			});
		}
	}
);

export const updateProfileCV = createAsyncThunk(
	'profile/updateCv',
	async (options, {getState, rejectWithValue}) => {
		try {
			const response = await agent
				.put(`${getState().status.apiUrl}/profile/updateCv`)
				.send(options)
				.set('Accept', 'application/json');
			return response.body;
		} catch (error) {
			return rejectWithValue({
				message: error.response?.body?.errors[0]?.message || error.message,
				params: error.response?.body?.input?.params || {}
			});
		}
	}
);
export const removeImage = createAsyncThunk(
	'profile/removeImage',
	async ({profileId}, {getState}) => {
		const {csrf, sid} = getState().account.data.api;
		const apiUrl = getState().status.apiUrl;
		return await agent
			.del(`${apiUrl}/profile/unsetMainImage`)
			.send({profileId: profileId})
			.set('Accept', 'application/json')
			.set('Authorization', `Bearer ${sid}`)
			.set('X-CSRF-Token', csrf);
	}
);


export const uploadImage = createAsyncThunk(
	'profile/uploadImage',
	async ({profileId, file}, {getState}) => {
		const {csrf, sid} = getState().account.data.api;
		const apiUrl = getState().status.apiUrl;
		return await agent
			.post(`${apiUrl}/profile/setMainImage`)
			.field('profileId', profileId)
			.attach('file', file)
			.set('Accept', 'application/json')
			.set('Authorization', `Bearer ${sid}`)
			.set('X-CSRF-Token', csrf);
	}
);


export const profilesAdapter = createEntityAdapter();
const initialState = profilesAdapter.getInitialState({
	loading: 'idle',
	error: null,
	amount: 0,
	page: 0,
	totalAmount: 0,
	pagesAmount: 0,
	currentProfileId: null,

	// fullfilled by entityAdapter
	ids: [],
	entities: []
});

export const profileSlice = createSlice({
	name: 'profile',
	initialState: initialState,
	reducers: {
		setCurrentProfileId: (state, action) => {
			if (state.currentProfileId !== action.payload) {
				state.currentProfileId = action.payload;
				Cookies.set('arc-profile', state.currentProfileId, {expires: 99999});
			}
		},
		//getCurrent
		clearCurrentProfile: (state) => {
			state.currentProfileId = null;
			profilesAdapter.removeAll(state);
		},
		setCurrentProfileData: (state, action) => {
			profilesAdapter.updateOne(state, {
				id: state.currentProfileId,
				changes: action.payload
			});
		},
		setProfileData: (state, action) => {
			profilesAdapter.upsertOne(state, action.payload);
		},
		addMainProfile: (state, action) => {
			profilesAdapter.addOne(state, action.payload);
		},

		updateIsGroup: (state, action) => {
			const {profileId, isGroup} = action.payload;
			const profile = state.entities[profileId];
			if (profile) {
				profile.isGroup = isGroup;
			}
		}
	},
	extraReducers: (builder) => {
		// Fetch profiles
		builder.addCase(fetchProfiles.pending, (state, action) => {
			state.loading = 'loading';
			state.error = null;
		});
		builder.addCase(fetchProfiles.fulfilled, (state, action) => {
			state.loading = 'idle';
			state.error = null;
			state.amount = action.payload.collection.amount;
			state.page = action.payload.collection.page;
			state.totalAmount = action.payload.collection.totalAmount;
			state.pagesAmount = action.payload.collection.pagesAmount;
			profilesAdapter.upsertMany(state, action.payload.collection.items);
		});


		// Add profile
		builder.addCase(addProfile.pending, (state, action) => {
			state.loading = 'loading';
			state.error = null;
		});
		builder.addCase(addProfile.fulfilled, (state, action) => {
			if (action.payload && action.payload.status === 422) {
				state.loading = 'idle';
				state.error = action.payload.response.body;
			} else {
				state.loading = 'idle';
				state.error = null;
				profilesAdapter.addOne(state, action.payload.item);
			}
		});

		// Get profile
		builder.addCase(getProfile.pending, (state, action) => {
			state.loading = 'loading';
			state.error = null;
		});
		builder.addCase(getProfile.fulfilled, (state, action) => {
			if (action.payload && action.payload.status === 422) {
				state.loading = 'idle';
				state.error = action.payload.response.body;
			} else {
				state.loading = 'idle';
				state.error = null;
				profilesAdapter.upsertOne(state, action.payload.item);
			}
		});

		// Edit profile
		builder.addCase(updateProfile.pending, (state) => {
			state.loading = 'loading';
			state.error = null;  // Clear error state
		});
		builder.addCase(updateProfile.fulfilled, (state, action) => {
			if (action.payload && action.payload.status === 422) {
				state.error = action.payload.response.body; // save error info for display in frontend
			} else {
				state.loading = 'idle';
				state.error = null;  // Clear error state
				profilesAdapter.setOne(state, action.payload.item);
			}
		});
		builder.addCase(updateProfile.rejected, (state, action) => {
			state.loading = 'idle';
			state.error = action.payload;  // Set error state
		});

		// Edit profile settings
		builder.addCase(updateProfileSettings.pending, (state) => {
			state.loading = 'loading';
			state.error = null;  // Clear error state
		});
		builder.addCase(updateProfileSettings.fulfilled, (state, action) => {
			if (action.payload && action.payload.status === 422) {
				state.error = action.payload.response.body; // save error info for display in frontend
			} else {
				state.loading = 'idle';
				state.error = null;  // Clear error state
				profilesAdapter.setOne(state, action.payload.item);
			}
		});
		builder.addCase(updateProfileSettings.rejected, (state, action) => {
			state.loading = 'idle';
			state.error = action.payload;  // Set error state
		});


		// Update CV
		builder.addCase(updateProfileCV.pending, (state) => {
			state.loading = 'loading';
			//state.error = null;  // Clear error state
		});
		builder.addCase(updateProfileCV.fulfilled, (state, action) => {
			if (action.payload && action.payload.status === 422) {
				//state.error = null;
				state.error = action.payload.response.body; // save error info for display in frontend
			} else {
				state.loading = 'idle';
				state.error = null;  // Clear error state
				profilesAdapter.setOne(state, action.payload.item);
			}
		});
		builder.addCase(updateProfileCV.rejected, (state, action) => {
			state.loading = 'idle';
			state.error = action.payload;  // Set error state
		});

		// Upload profile image
		builder.addCase(uploadImage.fulfilled, (state, action) => {
			state.loading = 'idle';
			const file = action.payload.body.item;
			const newProfile = deepClone(state.entities[file.profileId]);
			newProfile.mainImage = file;
			profilesAdapter.setOne(state, newProfile);
			state.error = null;
		});
		builder.addCase(uploadImage.pending, (state, action) => {
			state.loading = 'loading';
		});
		builder.addCase(uploadImage.rejected, (state, action) => {
			state.loading = 'idle';
			state.error = action.payload;
		});

		builder.addCase(removeImage.fulfilled, (state, action) => {
			state.loading = 'idle';
			const newProfile = deepClone(state.entities[action.meta.arg.profileId]);
			newProfile.mainImage = null;
			profilesAdapter.setOne(state, newProfile);
			state.error = null;
		});
		builder.addCase(removeImage.pending, (state, action) => {
			state.loading = 'loading';
			state.error = action.payload;
		});
		builder.addCase(removeImage.rejected, (state, action) => {
			state.loading = 'idle';
			state.error = action.payload;
		});
	}
});

export const {
	setCurrentProfileId,
	clearCurrentProfile,
	setCurrentProfileData,
	addMainProfile,
	updateMainImageId,
	updateIsGroup,
	setProfileData
} = profileSlice.actions;

export const {
	selectById: selectProfileById,
	selectIds: selectProfileIds,
	selectEntities: selectProfileEntities,
	selectAll: selectAllProfiles,
	selectTotal: selectTotalProfiles
} = profilesAdapter.getSelectors((state) => state.profile);

export default profileSlice.reducer;
