import {connect} from 'pwa-helpers';
import {LitElement, html} from 'lit';
import {customElement} from 'lit/decorators/custom-element.js';
import {msg} from '@lit/localize';
import {router} from 'lit-element-router';

import {when} from 'lit/directives/when.js';

import store from '../../store';

import style from '../../../../scss/accounts.scss';
import formPageStyle from '../../../../scss/formPage.scss';
import arrowRight from '../../icons/arrowRight';

@customElement('arc-admin-accounts')
export class AdminAccounts extends connect(store)(LitElement) {
	static styles = [formPageStyle, style];

	constructor() {
		super();
		store.subscribe(() => this.requestUpdate());
	}

	stateChanged(state) {
		this.status = state.status;
		this.accountData = state.account.data.account;
		this.accountStatus = state.account.status;
		this.loggedIn = state.account.data.loggedIn;
	}

	async connectedCallback() {
		super.connectedCallback();
	}

	disconnectedCallback() {
		super.disconnectedCallback();
	}

	render() {
		const state = store.getState();
		return html`
			<div class="accounts">
				<arc-toolbar>
					<div class="leftSlot" slot="left">
						<arc-breadcrumbs>
							<span>${msg('Accounts')}</span>
						</arc-breadcrumbs>
					</div>
					<div class="rightSlot" slot="right">
						<arc-routerlink route="admin-account-create" .icon=${arrowRight} nounderline>
							${msg('Create New Account')}
						</arc-routerlink>
					</div>
				</arc-toolbar>

				<h2>${msg('Accounts')}</h2>

				${when(
					this.loggedIn,
					() => html`
						<arc-account-list listType="${'all'}" .showSearch="${true}" .showFilter="${true}"></arc-account-list>`,
					() => html`
						<arc-routerlink class="requestToken" route="requestToken">
							${msg('Request Token')}
						</arc-routerlink>
					`
				)}

		`;
	}
}
