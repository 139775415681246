import {fetchFile} from '../slices/fileSlice';

export const fileMiddleware = (store) => (next) => async (action) => {
	const result = next(action);
	// store
	if (action.type === 'profile/get/fulfilled') {
		if (action.payload.item.mainImageId && action.payload.item.mainImageId !== 0) {
			//store.dispatch(fetchFile({id: action.payload.item.mainImageId}));
		}
	}
	if (action.type === 'profile/uploadImage/fulfilled') {
		//const file = action.payload.body.file;
		//console.log('fileMiddleware profile/uploadImage/fulfilled: ', file);
		//store.dispatch(fetchFile({id: file.id}));
	}
	if (action.type === 'profile/removeImage/fulfilled') {
		console.log('fileMiddleware profile/removeImage/fulfilled: ', action.meta.arg);
	}

	return result;
};
