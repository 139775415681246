import {LitElement, html, css} from 'lit';
import {customElement, property} from 'lit/decorators.js';
import {navigator, router} from 'lit-element-router';
import {msg, str} from '@lit/localize';
import {connect} from 'pwa-helpers';

import style from '../../../../scss/accountListItem.scss';
import store from '../../store';
import arrowRight from '../../icons/arrowRight';
import {openDialog} from '../../slices/statusSlice';

@customElement('arc-account-list-item')
export class AccountListItem extends connect(store)(navigator(LitElement)) {
	static styles = [style];

	static properties = {
		account: {type: Object},
		currentLanguage: {type: String},
		customClass: {type: String},
		isDropdownOpen: {type: Boolean},
	};

	constructor() {
		super();
		this.isDropdownOpen = false;
		store.subscribe(() => this.requestUpdate());
	}

	connectedCallback() {
		super.connectedCallback();
	}

	toggleDropdown() {
		this.isDropdownOpen = !this.isDropdownOpen;
	}

	closeDropdown() {
		this.isDropdownOpen = false;
	}

	handleDeleteAccount() {
		const title = this.account.name;
		store.dispatch(
			openDialog({
				title: msg('Remove Account'),
				warningMessage: msg(
					str`Removing Account "${title}" will immediately remove it from your list of Accounts.`
				),
				labelCancel: msg('Cancel'),
				labelConfirm: msg('Remove Account'),
			})
		);
		this._handleConfirm = async () => {
			// await store.dispatch(deleteAccount({id: this.account.id}));
			this.dispatchEvent(
				new CustomEvent('account-deleted', {
					bubbles: true,
					composed: true,
					detail: {artworkId: this.account.id},
				})
			);
			window.removeEventListener('confirm', this._handleConfirm);
		};
		window.addEventListener('confirm', this._handleConfirm);
		this.closeDropdown();
	}

	render() {
		const classes = this.customClass ? `accountItem ${this.customClass}` : 'accountItem';
		const isBinRoute = window.location.pathname.includes('artworks/bin');
		return html`
			<div class="${classes}">
				<div class="cell">${this.account.role}</div>
				<div class="cell">
					<arc-routerlink route="admin-account" noUnderline .params=${{accountId: this.account.id}}>
						${this.account.name}
					</arc-routerlink>
				</div>
				<div class="cell">
					<arc-routerlink route="admin-account" noUnderline .params=${{accountId: this.account.id}}>
						${this.account.email}
					</arc-routerlink>
				</div>
				<div class="cell">
					${this.account.isEnabled ? msg('Yes') : msg('No')}
				</div>
				<div class="cell">
					${this.account.isTest ? msg('Yes') : msg('No')}
				</div>
				<div class="cell">
					${this.account.hasLoggedInOnce ? msg('Yes') : msg('No')}
				</div>
				<div class="cell">
					${this.account.invitedByName}
				</div>

				<div class="cell accountListButtons">
					<arc-routerlink
						route="admin-account"
						noUnderline
						.params=${{accountId: this.account.id, mode: 'edit'}}
					>
						${msg('Edit')} ${arrowRight}
					</arc-routerlink>
				</div>
			</div>
    `;
	}
}
