import {connect} from 'pwa-helpers';
import {LitElement, html} from 'lit';
import {customElement} from 'lit/decorators/custom-element.js';
import {msg} from '@lit/localize';
import {when} from 'lit/directives/when.js';
import {navigator, router} from 'lit-element-router';

import store from '../../store';

import style from '../../../../scss/home.scss';
import {selectCurrentProfileId} from '../../selectors';
import getRoutes from '../../routes';
import {getProfile, selectProfileById} from '../../slices/profileSlice';
import {deepClone} from '../../util/deepClone';

@customElement('arc-home')
export class Home extends connect(store)(router(LitElement)) {
	static styles = [style];

	constructor() {
		super();
		store.subscribe(() => this.requestUpdate());
	}

	stateChanged(state) {
		this.status = state.status;
		this.accountData = state.account.data.account;
		this.accountStatus = state.account.status;
		this.loggedIn = state.account.data.loggedIn;
		this.profileId = selectCurrentProfileId(state);
	}

	static get routes() {
		return getRoutes();
	}
	async router(route, params, query, data) {
		this.params = params;
	}
	async connectedCallback() {
		super.connectedCallback();
	}

	disconnectedCallback() {
		super.disconnectedCallback();
	}

	render() {
		const state = store.getState();
		return html`
			<div class="home">
				<arc-welcome>
				${when(
					this.loggedIn,
					() => html`
							<p>
								${msg('home_welcome_text')}
							</p>
					`,
					() => html`
						<arc-request-token></arc-request-token>
					`
				)}
				</arc-welcome>
			</div>
		`;
	}
}
