import {connect} from 'pwa-helpers';
import {LitElement, html} from 'lit';
import {customElement} from 'lit/decorators/custom-element.js';
import {classMap} from 'lit/directives/class-map.js';
import {when} from 'lit/directives/when.js';

import {msg} from '@lit/localize';
import {navigator, router} from 'lit-element-router';
import deepEqual from 'deep-equal';

import store from '../../store';
import getRoutes from '../../routes';
import style from '../../../../scss/artistSettings.scss';
import formPageStyle from '../../../../scss/formPage.scss';
import linkstyle from '../../../../scss/link.scss';
import loadingSpinnerStyle from '../../../../scss/loadingSpinner.scss';

import {
	selectProfileById,
	getProfile, updateProfileSettings, updateProfile
} from '../../slices/profileSlice';
import {profileDisplayName} from '../../util/profileDisplayName';
import {deepClone} from '../../util/deepClone';
import {
	selectCurrentAccountId,
	selectProfileDefaultLanguage,
	selectCurrentProfileId,
	selectCurrentProfile
} from '../../selectors';
import {inviteUser} from '../../slices/accountSlice';
import {showMessage} from '../../slices/statusSlice';

@customElement('arc-artist-settings')
export class ArtistSettings extends connect(store)(router(navigator(LitElement))) {
	static styles = [style, formPageStyle, linkstyle, loadingSpinnerStyle];

	static properties = {
		localFormData: {type: Object, hasChanged: (n, o) => !deepEqual(n, o)},
		errors: {type: Object,  hasChanged: (n, o) => !deepEqual(n, o)},
		inviteUserEmail: {type: String},
		edit: {type: Boolean}
	};

	constructor() {
		super();
		this.localFormData = null;
		this.inviteUserEmail = '';
		this.errors = null;
		this.isLoading = false;

		store.subscribe(() => this.requestUpdate());
	}

	stateChanged(state) {
		this.state = state;
		this.status = state.status;
		this.errors = state.profile.error;
		this.isLoading = state.profile.loading === 'loading';
		this.currentAccountId = selectCurrentAccountId(state);
		this.profile = selectCurrentProfile(state);

	}

	static get routes() {
		return getRoutes();
	}

	async router(route, params, query, data) {
		this.params = params;

		this.edit = true; // params.mode === 'edit';
		if (route === 'artist-settings' && this.profileId !== params.id) {
			this.profileId = params.id;
			await store.dispatch(getProfile({id: this.profileId})); // fetch profile from backend
			// clone data for local editing (component state)
			this.localFormData = selectProfileById(this.state, this.profileId);
		}
		this.requestUpdate();
	}

	handleValueChange(fieldData, value) {
		const section = fieldData.section;
		const field = fieldData.field;

		// update localFormData with new value in section and field
		const newData = deepClone(this.localFormData); // clone current date

		// checkbox: convert string to number if it is a integer
		if (fieldData.type === 'checkbox' && typeof value === 'string' && value.match(/^\d+$/)) {
			value = parseInt(value, 10);
		}

		if (section) {
			if (newData[section]?.[field]) {
				newData[section][field] = value; // update field
			} else {
				newData[section] = {...newData[section], [field]: value}; // create new field
			}
		} else {
			newData[field] = value; // update field
		}
		this.localFormData = newData; // assign (updates reference, triggers update)
	}

	// saveProfile: Edit user profile data, send it to API
	saveProfileSettings() {
		// use only id and defaultLang from this.localFormData
		store.dispatch(updateProfileSettings({
			id: this.localFormData.id,
			defaultLang: this.localFormData.defaultLang,
		}));
		store.dispatch(updateProfile(
			this.localFormData
		));
	}


	connectedCallback() {
		super.connectedCallback();
	}

	disconnectedCallback() {
		super.disconnectedCallback();
	}

	formHasChanged() {
		// check if local form data is different
		const newData = deepClone(this.localFormData);
		const oldData = deepClone(selectProfileById(this.state, this.profileId));
		delete newData.modified;
		delete oldData.modified;
		return !deepEqual(newData, oldData);
	}

	render() {
		//loading message before the form loads with data
		if (this.isLoading || !this.localFormData) {
			return html`
				<div class="loading-overlay">
					<div class="spinner"></div>
				</div>
			`;
		}

		const languageOptions = [
			{value: 'de', label: msg('German')},
			{value: 'en', label: msg('English')},
		];

		const languageSettingsFields = [
			{
				type: 'select',
				title: msg('Default Content Language'),
				field: 'defaultLang',
				section: '',
				options: languageOptions.map((option) => ({
					id: option.value,
					name: [{lang: 'en', value: option.label}],
				})),
				data: this.localFormData,
				errors: this.errors,
				multilingual: false,
				required: false,
				handler: this.handleValueChange.bind(this),
			},
		];

		const remainsInputFields = [
			{
				type: 'checkbox',
				title: msg('Is Remains'),
				field: 'isDead',
				section: 'singleInfo',
				tooltip: '',
				data: this.localFormData,
				errors: this.errors,
				handler: this.handleValueChange.bind(this)
			}
		];

		return html`
			<div class="formPage">
				<arc-toolbar class="toolbar">
					<div slot="left">
						<arc-breadcrumbs>
							<arc-routerlink route="artist">${msg('Profile')}: ${profileDisplayName(this.profile)}</arc-routerlink>
							<span>${msg('Settings')}</span>
						</arc-breadcrumbs>
					</div>
					<div slot="right">
						<arc-button
							title="${msg('Save Profile Settings')}"
							type="primary"
							.additionalInfo="${false}"
							?disabled="${!this.formHasChanged()}"
							class="saveProfileButton ${classMap({pending: this.isLoading})}"
							@click=${this.saveProfileSettings}
						></arc-button>
					</div>
				</arc-toolbar>
				<header>
					<h2 class="pageTitle">${msg('Profile Settings')}</h2>
				</header>

				<section>
					<div class="leftColumn">
						<h2 class="sectionTitle">${msg('Language Settings')}</h2>
						<p>${msg('description_content_language')}</p>
						<arc-form-grid
							class="languageSettings"
							.fields=${languageSettingsFields}
							.localFormData=${this.localFormData}
							.errors=${this.errors}
						</arc-form-grid>
					</div>
					<div class="rightColumn">
					</div>
				</section>

				${when(
					!this.localFormData?.isGroup,
					() => html`
						<section>
							<div class="leftColumn">
								<h2 class="sectionTitle">${msg('Remains')}</h2>
								<arc-form-grid
									class="remains"
									.fields=${remainsInputFields}
									.localFormData=${this.localFormData}
									.errors=${this.errors}
								</arc-form-grid>
							</div>
							<div class="rightColumn">
							</div>
						</section>`
				)}

    `;
	}
}
