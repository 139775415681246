import {LitElement, html, css} from 'lit';
import {navigator, router} from 'lit-element-router';
import {classMap} from 'lit/directives/class-map.js';
import {customElement} from 'lit/decorators/custom-element.js';
import {map} from 'lit/directives/map';
import {range} from 'locutus/php/array';
import {routeUrl} from '../../util/routeUrl';
import getRoutes from '../../routes';
import style from '../../../../scss/pagination.scss';

@customElement('arc-pagination')
export class Pagination extends navigator(router(LitElement)) {
	static properties = {
		route: {type: String},
		params: {type: Object},
		query: {type: Object},
		page: {type: Number},
		totalPages: {type: Number}
	};

	static styles = [style];

	constructor() {
		super();
		this.page = 1;
		this.totalPages = 10;
	}

	static get routes() {
		return getRoutes();
	}

	router(route, params, query, data) {
		// merge this.params into routerParams
		this.params = params;
		this.query = query;
		this.page = query.page ? parseInt(query.page, 10) : 1;
	}

	render() {
		return html`
			<div class="pages">
				${map(
					range(1, this.totalPages, 1),
					(i) => html`
						<a
							class="page ${classMap({active: this.page === i})}"
							href="${routeUrl(this.route, this.params, {...this.query, ...{page: i}})}">
							${i}
						</a>`
				)}
			</div>
		`;
	}

}
