import {LitElement, html} from 'lit';
import {customElement} from 'lit/decorators.js';
import {navigator} from 'lit-element-router';
import {repeat} from 'lit/directives/repeat.js';
import {msg} from '@lit/localize';
import {connect} from 'pwa-helpers';

import style from '../../../../scss/invitedListItem.scss';
import store from '../../store';
import {
	grantPermissions,
	revokePermissions,
	selectInvitedAccountById
} from '../../slices/accountSlice';
import {selectProfileById} from '../../slices/profileSlice';
import {profileDisplayName} from '../../util/profileDisplayName';

@customElement('arc-invited-list-item')
export class InvitedListItem extends connect(store)(navigator(LitElement)) {
	static styles = [style];

	static properties = {
		accountId: {type: String}
	};

	availablePermissions = [
		'profile/view',
		'profile/edit',
		'artwork/view',
		'artwork/edit'
	]

	constructor() {
		super();
		this.isDropdownOpen = false;
		this.permissions = [];
		this.accountId = null;
		store.subscribe(() => this.requestUpdate());
	}

	stateChanged(state) {
		this.state = state;
		if (this.accountId) {
			this.account = selectInvitedAccountById(state, this.accountId);
			this.accountProfilesPermissions = this.account.permissions;
			this.profilesWithPermissions = this.account.permissions
				.map((permission) => permission[0])
				.filter((id) => id !== '0')
				.map((profileId) => selectProfileById(this.state, profileId));
		}
	}

	connectedCallback() {
		super.connectedCallback();
	}

	toggleDropdown() {
		this.isDropdownOpen = !this.isDropdownOpen;
	}

	closeDropdown() {
		this.isDropdownOpen = false;
	}

	hasPermission(profilePermission, profileId) {
		const profilePermissions = this.accountProfilesPermissions.find((p) => p[0] + '' === profileId + '')[1];
		return profilePermissions.includes(profilePermission) ? 1 : 0;
	}
	onChangePermission(permission, profileId, value) {
		if (value=== 1) {
			store.dispatch(
				grantPermissions({
					targetAccountId: this.account.id,
					profileId: profileId,
					permissions: [permission]
			}));
		} else {
			store.dispatch(
				revokePermissions({
					targetAccountId: this.account.id,
					profileId: profileId,
					permissions: [permission]
				}));
		}
	}

	render() {
		if (!this.accountId) return html``;

		return html`
			<div class="invitedItem">
				<div class="cell">
						${this.account.name}
				</div>
				<div class="cell">
						${this.account.email}
				</div>
				<div class="cell">
					${this.account.hasLoggedInOnce ? msg('Yes') : msg('No')}
				</div>
				<div class="cell">
					${repeat(
						this.profilesWithPermissions,
						(profile) => profile.id,
						(profile, index) => html`
							<div class="permissionList">
								<div class="profileName">${profileDisplayName(profile)}:</div>
								<arc-checkbox
									title="${msg('View')}"
									?disabled=${true}
									?noFieldTitle=${true}
									.value=${this.hasPermission('profile/view', profile.id)}
									@value-change=${(ev) => this.onChangePermission('profile/view', profile.id, ev.detail.value)}>
								</arc-checkbox>
								<arc-checkbox
									title="${msg('Edit')}"
									.noFieldTitle=${true}
									.value=${this.hasPermission('profile/edit', profile.id)}
									@value-change=${(ev) => this.onChangePermission('profile/edit', profile.id, ev.detail.value)}>
								</arc-checkbox>
							</div>
					`)}
				</div>
				<div class="cell">
					${repeat(
						this.profilesWithPermissions,
						(profile) => profile.id,
						(profile, index) => html`
							<div class="permissionList">
								<div class="profileName">${profileDisplayName(profile)}:</div>
								<arc-checkbox
									title="${msg('View')}"
									?disabled=${true}
									?noFieldTitle=${true}
									.value=${this.hasPermission('artwork/view', profile.id)}
									@value-change=${(ev) => this.onChangePermission('artwork/view', profile.id, ev.detail.value)}>
								</arc-checkbox>
								<arc-checkbox
									title="${msg('Edit')}"
									.noFieldTitle=${true}
									.value=${this.hasPermission('artwork/edit', profile.id)}
									@value-change=${(ev) => this.onChangePermission('artwork/edit', profile.id, ev.detail.value)}>
								</arc-checkbox>
							</div>
					`)}
				</div>
			</div>
    `;
	}
}
