import {LitElement, html, css} from 'lit';
import {customElement} from 'lit/decorators/custom-element.js';
import {when} from 'lit/directives/when.js';
import {classMap} from 'lit/directives/class-map.js';
import {navigator, router} from 'lit-element-router';
import {msg} from '@lit/localize';
import {connect} from 'pwa-helpers';
import store from '../../store';
import style from '../../../../scss/header.scss';

import {logout} from '../../slices/accountSlice';
import {
	navigateTo, setAccountDropdownState,
	setAdminDropdownState, setArtworksDropdownState,
	setProfileDropdownState
} from '../../slices/statusSlice';
import {
	selectCurrentProfileId, selectIsOwnerOfCurrentProfile,
	selectLanguage,
	selectProfileDisplayName, selectRole
} from '../../selectors';
import down from '../../icons/down';
import {trimObject} from '../../util/trimObject';

@customElement('arc-header')
export class Header extends connect(store)(navigator(LitElement)) {
	static styles = [style];

	static properties = {
		profileId: {type: String},
		profileDisplayName: {type: String},
	};

	constructor() {
		super();
		this.isMenuOpen = false;
		this.profileId = null;
		this.profileDisplayName = '';
		store.subscribe(() => this.requestUpdate());
	}

	stateChanged(state) {
		this.loggedIn = state.account.data.loggedIn;
		this.account = state.account.data.account;
		this.lang = selectLanguage(state);
		this.profileId = selectCurrentProfileId(state);
		this.profileDisplayName = selectProfileDisplayName(state);

		this.role = selectRole(state);

		this.isAccountDropdownOpened = state.status.isAccountDropdownOpened;
		this.isAdminDropdownOpened = state.status.isAdminDropdownOpened;
		this.isProfileDropdownOpened = state.status.isProfileDropdownOpened;
		this.isArtworksDropdownOpened = state.status.isArtworksDropdownOpened;
	}

	toggleAccountDropdown() {
		store.dispatch(setAccountDropdownState(!this.isAccountDropdownOpened));
	}
	handleAccountDropdown(state) {
		store.dispatch(setAccountDropdownState(state));
	}

	toggleAdminDropdown() {
		store.dispatch(setAdminDropdownState(!this.isAdminDropdownOpened));
	}
	handleAdminDropdown(state) {
		store.dispatch(setAdminDropdownState(state));
	}

	toggleProfileDropdown() {
		store.dispatch(setProfileDropdownState(!this.isProfileDropdownOpened));
	}
	handleProfileDropdown(state) {
		store.dispatch(setProfileDropdownState(state));
	}

	toggleArtworksDropdown() {
		store.dispatch(setArtworksDropdownState(!this.isArtworksDropdownOpened));
	}
	handleArtworksDropdown(state) {
		if (this.isArtworksDropdownOpened !== state) {
			store.dispatch(setArtworksDropdownState(state));
		}
	}

	async onLogout(input) {
		await store.dispatch(logout());
		store.dispatch(navigateTo({route: 'home', params: {language: this.lang}}));
	}

	toggleMenu() {
		this.isMenuOpen = !this.isMenuOpen;
		this.requestUpdate();
	}

	render() {
		const s = store.getState();

		return html`
			<div class="header">
				<div class="headerLeft">
					<arc-routerlink class="headerLogo" route="home" noUnderline>
						<img src="/assets/img/kfn-logo.svg" alt="Stiftung Kunstfonds" />
					</arc-routerlink>

					<div class="headerTitle">
						<arc-routerlink route="home" noUnderline mobileSmall>Werkverzeichnung</arc-routerlink>
					</div>
				</div>

				<div class="headerRight">
					${when(
						this.profileId,
						() => html`
							<div class="headerItem desktop headerDropdown ${classMap({jsActive: this.isArtworksDropdownOpened})}"
									 @mouseover="${() => this.handleArtworksDropdown(true)}"
									 @mouseout="${() => this.handleArtworksDropdown(false)}">
								<div class="headerDropdownTitle" @click="${this.toggleArtworksDropdown}">
									<div class="headerDropdownTitleText">${msg('Artworks')}</div>
									${down}
								</div>
								<div class="headerDropdownContent">
									<div class="headerDropdownContentLink">
										<arc-routerlink route="artworks" .params=${{id: this.profileId}}
																		.query=${trimObject(this.artworkQuery)} noUnderline>
											${msg('Edit Artworks')}
										</arc-routerlink>
									</div>
									<div class="headerDropdownContentLink">
										<arc-routerlink route="artworks-bin" .params=${{id: this.profileId}}
																		noUnderline>${msg('Removed artworks')}</arc-routerlink>
									</div>
									<div class="headerDropdownContentLink">
										<arc-routerlink route="series-list" .params=${{id: this.profileId}}
																		noUnderline>${msg('Edit Series')}</arc-routerlink>
									</div>
								</div>
							</div>

							<div class="headerItem desktop headerDropdown ${classMap({jsActive: this.isProfileDropdownOpened})}"
									 @mouseover="${() => this.handleProfileDropdown(true)}"
									 @mouseout="${() => this.handleProfileDropdown(false)}">
								<div class="headerDropdownTitle" @click="${this.toggleProfileDropdown}">
									<div class="headerDropdownTitleText"><span class="headerLabel">${msg('Profile')}</span><span class="headerName">${this.profileDisplayName}</span></div>
									${down}
								</div>
								<div class="headerDropdownContent">
									<div class="headerDropdownContentLink">
										<arc-routerlink route="artist" .params=${{id: this.profileId}} noUnderline>
											${msg('Edit Profile')}
										</arc-routerlink>
									</div>
									<div class="headerDropdownContentLink">
										<arc-routerlink route="artist-cv" .params=${{id: this.profileId}} noUnderline>
											${msg('Edit CVs')}
										</arc-routerlink>
									</div>
									<div class="headerDropdownContentLink">
										<arc-routerlink route="artist-settings" .params=${{id: this.profileId}} noUnderline>
											${msg('Profile Settings')}
										</arc-routerlink>
									</div>
									${when(selectIsOwnerOfCurrentProfile(s), () => html`
										<div class="headerDropdownContentLink">
											<arc-routerlink route="artist-share" .params=${{id: this.profileId}} noUnderline>
												${msg('Collaboration')}
											</arc-routerlink>
										</div>
									`)}
								</div>`
					)}


					${when(
						this.loggedIn && (this.role === 'admin' || this.role === 'operator'),
						() => html`
							<div
								class="headerItem desktop headerDropdown ${classMap({jsActive: this.isAdminDropdownOpened})}"
								@mouseover="${() => this.handleAdminDropdown(true)}"
								@mouseout="${() => this.handleAdminDropdown(false)}">
								<div class="headerDropdownTitle" @click="${this.toggleAdminDropdown}">
									<div class="headerDropdownTitleText">${msg('Admin')}</div>
									${down}
								</div>

								<div class="headerDropdownContent">
									<arc-is-permitted .requirements=${['account/viewUser', 'account/viewAdmin', 'account/viewOperator']}>
										<div class="headerDropdownContentLink">
											<arc-routerlink route="admin-accounts" noUnderline>
												${msg('Accounts')}
											</arc-routerlink>
										</div>
									</arc-is-permitted>
								</div>
							</div>`
					)}

					${when(
						this.loggedIn,
						() => html`
							<div
								class="headerItem desktop headerDropdown ${classMap({jsActive: this.isAccountDropdownOpened})}"
								@mouseover="${() => this.handleAccountDropdown(true)}"
								@mouseout="${() => this.handleAccountDropdown(false)}">
							<div class="headerDropdownTitle" @click="${this.toggleAccountDropdown}">
								<div class="headerDropdownTitleText"><span class="headerLabel">${msg('Account')}</span> <span class="headerName">${this.account.email}</span></div>
									${down}
								</div>

								<div class="headerDropdownContent">
									<div class="headerDropdownContentLink language">
										<span>${msg('UI Language')}:</span> <arc-language-switch class="languageSwitch"></arc-language-switch>
									</div>
									<div class="headerDropdownContentLink">
										<arc-routerlink route="account" .params=${{id: this.account?.id}} noUnderline>
											${msg('Account Settings')}
										</arc-routerlink>
									</div>

									<div class="headerDropdownContentLink">
										<arc-link href="#" @click=${this.onLogout} noUnderline>
											${msg('Logout')}
										</arc-link>
									</div>
								</div>
							</div>`,
						() => html`
							<div class="headerItem desktop login">
								<arc-routerlink
									route="home"
									noUnderline
								>
									${msg('Login')}
								</arc-routerlink>
							</div>
							</div>
						`
					)}


					<div class="headerItem headerMenu mobileOnly ${this.isMenuOpen ? 'toggled' : ''}">
						<div class="headerMenuTitle">${msg('Menu')}</div>
						<div class="headerMenuIcon" @click="${this.toggleMenu}">
							<div class="line ${classMap({lineOpen: this.isMenuOpen})}"></div>
							<div class="line ${classMap({lineOpen: this.isMenuOpen})}"></div>
							<div class="line ${classMap({lineOpen: this.isMenuOpen})}"></div>
						</div>
					</div>
				</div>
			</div>
			<div class="mobileOverlay">
				<arc-menu-overlay
					.isOpen="${this.isMenuOpen}"
					.loggedIn="${this.loggedIn}"
					.profileDisplayName="${selectProfileDisplayName(s)}"
					@menu-closed="${() => (this.isMenuOpen = false)}"
				></arc-menu-overlay>
			</div>

			<arc-toast-banner></arc-toast-banner>
    `;
	}
}
