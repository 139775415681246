import {connect} from 'pwa-helpers';
import {LitElement, html} from 'lit';
import {customElement} from 'lit/decorators/custom-element.js';
import {msg} from '@lit/localize';
import deepEqual from 'deep-equal';

import store from '../store';
import style from '../../../scss/formGrid.scss';

@customElement('arc-form-grid')
export class FormGrid extends connect(store)(LitElement) {
	static styles = [style];

	static properties = {
		fields: {type: Array},
		errors: {type: Array, hasChanged: (n, o) => !deepEqual(n, o)},
		disabled: {type: Boolean},
	};

	constructor() {
		super();

		this.parent = null;
		this.fields = null;
		this.erros = null;
		this.disabled = false;

		this.currentLanguage = 'en';
		store.subscribe(() => this.requestUpdate());
	}

	stateChanged(state) {
		this.state = state;
		this.status = state.status;
	}

	connectedCallback() {
		super.connectedCallback();
	}

	disconnectedCallback() {
		super.disconnectedCallback();
	}

	handleChange(field, value) {
		if (field.handler) {
			return field.handler(field, value);
		} else {
			console.error('No handler for field', field);
			return () => {};
		}
	}

	renderField(field) {
		let value;
		let errors;
		if (field.section) {
			value = field.data?.[field.section]?.[field.field];
			errors = field.errors?.params?.[field.section]?.params?.[field.field];
		} else {
			value = field.data?.[field.field];
			errors = field.errors?.params?.[field.field];
		}
		switch (field.type) {
			case 'textInput':
				return this.renderTextField(field, value, errors);
			case 'number':
				return this.renderNumberField(field, value, errors);
			case 'date':
				return this.renderDateField(field, value, errors);
			case 'multilingualTextInput':
				return this.renderMultilingualField(field, value, errors);
			case 'checkbox':
				return this.renderCheckboxField(field, value, errors);
			case 'select':
				return this.renderSelectField(field, value, errors);
			case 'radio':
				return this.renderRadioField(field, value, errors);
			case 'tags':
				return this.renderMultiselectTagsField(field, value, errors);
			case 'series':
				return this.renderSeriesField(field, value, errors);
			case 'multiselect':
				return this.renderMultiselectField(field, value, errors);
			default:
				return html` <div>[unknown field type: ${field.type}]</div>`;
		}
	}

	renderTextField(field, value, errors) {
		return html`
			<arc-text-input
				class="formInput ${field.field}"
				style="grid-area: ${field.field}"
				.title="${field.title}"
				.placeholder="${field.placeholder}"
				.disabled=${this.disabled}
				.multiline=${field.multiline}
				.value=${value}
				.errors=${errors}
				.required=${field.required}
				.tooltip="${field.tooltip}"
				@value-change=${(e) => this.handleChange(field, e.detail.value)}
			></arc-text-input>
		`;
	}

	renderNumberField(field, value, errors) {
		return html`
			<arc-number
				class="formInput ${field.field}"
				style="grid-area: ${field.field}"
				.title="${field.title}"
				?disabled=${this.disabled}
				.value=${value}
				.errors=${errors}
				.required=${field.required}
				.tooltip="${field.tooltip}"
				@value-change=${(e) => this.handleChange(field, e.detail.value)}
			></arc-number>
		`;
	}

	renderDateField(field, value, errors) {
		return html`
			<arc-date
				class="formInput ${field.field}"
				style="grid-area: ${field.field}"
				.title="${field.title}"
				.granularity=${field.granularity}
				?disabled=${this.disabled}
				.value=${value}
				.errors=${errors}
				.required=${field.required}
				.tooltip="${field.tooltip}"
				@value-change=${(e) => this.handleChange(field, e.detail.value)}
			></arc-date>
		`;
	}

	renderMultilingualField(field, value, errors) {
		return html`
			<arc-multilingual-input
				class="formInput ${field.field}"
				style="grid-area: ${field.field}"
				.title=${field.title}
				.placeholder=${field.placeholder}
				.value=${value}
				.errors=${errors}
				?disabled=${this.disabled}
				.required=${field.required}
				.multiline=${field.multiline}
				.tooltip="${field.tooltip}"
				@value-change=${(e) => this.handleChange(field, e.detail.value)}
			></arc-multilingual-input>
		`;
	}

	renderCheckboxField(field, value, errors) {
		return html`
			<arc-checkbox
				class="formInput checkbox ${field.field}"
				style="grid-area: ${field.field}"
				title="${field.title}"
				.value=${value}
				.showIcon=${true}
				.errors=${errors}
				?disabled=${this.disabled}
				.required=${field.required}
				.tooltip="${field.tooltip}"
				@value-change=${(e) => this.handleChange(field, e.detail.value ? 1 : 0)}
			>
			</arc-checkbox>
		`;
	}

	renderSelectField(field, value, errors) {
		return html`
			<arc-select
				class="formInput ${field.field}"
				style="grid-area: ${field.field}"
				.multiple=${field.multiple}
				.title="${field.title}"
				?disabled=${this.disabled}
				.options=${field.options}
				.value=${value}
				.errors=${errors}
				.required=${field.required}
				.tooltip="${field.tooltip}"
				@value-change=${(e) => this.handleChange(field, e.detail.value)}
			></arc-select>
		`;
	}

	renderRadioField(field, value, errors) {
		return html`
			<arc-radio
				class="formInput ${field.field}"
				style="grid-area: ${field.field}"
				.multiple=${field.multiple}
				.title="${field.title}"
				?disabled=${this.disabled}
				.options=${field.options}
				.value=${value}
				.errors=${errors}
				.required=${field.required}
				.tooltip="${field.tooltip}"
				@value-change=${(e) => this.handleChange(field, e.detail.value)}
			></arc-radio>
		`;
	}
	renderMultiselectTagsField(field, value, errors) {
		const options = Array.isArray(field.options) ? field.options : [];
		return html`
			<arc-multiselect-tags
				class="formInput ${field.field}"
				style="grid-area: ${field.field}"
				.options="${field.options}"
				.value="${value}"
				.labelField="${field.labelField || 'tag'}"
				.valueField="${field.valueField || 'id'}"
				.lang="${this.currentLanguage}"
				?disabled=${this.disabled}
				.title="${field.title}"
				.errors="${errors}"
				.required=${field.required}
				.tooltip="${field.tooltip}"
				@value-change="${(e) => this.handleChange(field, e.detail.value)}"
			></arc-multiselect-tags>
		`;
	}

	renderSeriesField(field, value, errors) {
		return html`
			<arc-series
				class="formInput ${field.field}"
				style="grid-area: ${field.field}"
				.title="${field.title}"
				?disabled=${this.disabled}
				.artworkId=${field.artworkId}
				.value=${value}
				.errors=${errors}
				.required=${field.required}
				.tooltip="${field.tooltip}"
				@value-change=${(e) => this.handleChange(field, e.detail.value)}
			></arc-series>
		`;
	}

	renderMultiselectField(field, value, errors) {
		return html`
			<arc-multiselect
				class="formInput ${field.field}"
				style="grid-area: ${field.field}"
				.options="${field.options}"
				.value="${value}"
				.labelField="${'name'}"
				.valueField="${'id'}"
				.lang="${this.currentLanguage}"
				?disabled=${this.disabled}
				.title="${field.title}"
				.errors="${errors}"
				.showSelectedCount="${field.showSelectedCount || false}"
				.showCounters="${field.showCounters || false}"
				.tooltip="${field.tooltip}"
				@value-change="${(e) => this.handleChange(field, e.detail.value)}"
			></arc-multiselect>
		`;
	}

	render() {
		return html` ${this.fields.map((field) => this.renderField(field))} `;
	}
}
