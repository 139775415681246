import {LitElement, html, css} from 'lit';
import {customElement} from 'lit/decorators.js';
import {connect} from 'pwa-helpers';
import {classMap} from 'lit/directives/class-map.js';
import {msg} from '@lit/localize';
import store from '../../store';
import style from '../../../../scss/uploadProgress.scss';

@customElement('arc-upload-progress')
export class UploadProgress extends connect(store)(LitElement) {
	static styles = [style];
	static properties = {
		open: {type: Boolean},
		message: {type: String},
	};

	constructor() {
		super();
		this.open = false;
		this.message = '';
		this.percentage = 0;
		this.timeout = null;
		store.subscribe(() => this.requestUpdate());
	}

	stateChanged(state) {
		if (state.artwork.uploadProgress.percentage === 100 || state.artwork.uploadProgress.percentage === 0) {
			this.timeout = setTimeout(() => {this.open = false;}, 2000);
		} else {
			clearTimeout(this.timeout);
			this.open = state.artwork.uploadProgress.percentage > 0;
		}
		this.message = state.artwork.uploadProgress.message;
		this.percentage = Math.ceil(state.artwork.uploadProgress.percentage);
	}


	render() {
		return html`
			<div class="modal ${classMap({open: this.open})}">
				<div class="modal-content" @click=${this.handleContentClick}>
					<h2 class="dialogTitle">${msg('Uploading File')}</h2>
					<div class="progressbar">
						<div class="progress" style="width: ${this.percentage}%;">${this.percentage}%</div>
					</div>
				</div>
			</div>
		`;
	}
}
