import {selectArtworkQuery, selectCurrentProfileId} from '../selectors';
import {routeUrl} from '../util/routeUrl';

export const artworkMiddleware = (store) => (next) => async (action) => {
	const result = next(action);

	// Fetch artworks when the artwork query is set
	if (action.type === 'artwork/setArtworkQuery') {
		const query = selectArtworkQuery(store.getState());
		const trimmedQuery = {};
		// copy all poperties from query, when not empty
		for (const key in query) {
			if (query[key]) {
				trimmedQuery[key] = query[key];
			}
		}
	}
	// redirect to the newly created artwork detail page after creation
	if (action.type ===  'artwork/create/fulfilled') {
		const artworkId = action.payload?.item?.id;
		if (artworkId) {
			const lang = store.getState().status.lang || {};
			const profileId = selectCurrentProfileId(store.getState());
			window.location.href = routeUrl('artwork', {
				language: lang,
				id: profileId,
				artworkId: artworkId,
			});
		}
	}

	return result;
};
