import {html, css, LitElement} from 'lit';
import {customElement} from 'lit/decorators.js';
import {connect} from 'pwa-helpers';
import store from '../../store';
import style from '../../../../scss/video.scss';

@customElement('arc-video')
class Video extends connect(store)(LitElement) {

	static styles = [style];

	static properties = {
		file: {type: Object},
		alt: {type: String},
	};

	constructor() {
		super();
		this.file = null;
		this.alt = '';
		store.subscribe(() => this.requestUpdate());
	}

	render() {
		if (!this.file || this.file.ext !== 'mp4') return null;

		return html`
			<video controls>
				<source src="${this.file.url}" type="${this.file.mime}" />
				${this.alt ? html`<track kind="captions" src="${this.alt}" default />` : ''} Your browser
				does not support the video tag.
			</video>
		`;
	}
}
