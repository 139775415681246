import {html, css, LitElement} from 'lit';
import {customElement} from 'lit/decorators/custom-element.js';
import {connect} from 'pwa-helpers';
import store from '../../store';
import style from '../../../../scss/button.scss';

@customElement('arc-button')
export class Button extends connect(store)(LitElement) {
	static properties = {
		title: {type: String},
		type: {type: String},
		showIcon: {type: Boolean},
		showLabel: {type: Boolean},
		disabled: {type: Boolean},
		action: {type: Function},
		additionalInfo: {type: Boolean},
		infoText: {type: String},
	};

	static styles = [style];

	constructor() {
		super();
		this.title = 'Button';
		this.type = 'primary';
		this.disabled = false;
		this.showIcon = false;
		this.showLabel = true;
		this.additionalInfo = false;
		this.infoText = '';
		store.subscribe(() => this.requestUpdate());
	}

	stateChanged(state) {
		this.lang = state.status.lang;
	}

	render() {
		const s = store.getState();
		return this.additionalInfo
			? html`
					<div class="info-wrapper">
						<button class="info-button ${this.type}" ?disabled=${this.disabled}>
							${this.showIcon ? html`<span class="icon"><slot></slot></span>` : ''}
							${this.showLabel ? html`<span class="label">${this.title}</span>` : null}
						</button>
						<span class="info-text">${this.infoText}</span>
					</div>
			  `
			: html`
					<button class="${this.type}" ?disabled=${this.disabled}>
						${this.showIcon ? html`<span class="icon"><slot></slot></span>` : ''}
						${this.showLabel ? html`<span class="label">${this.title}</span>` : null}
					</button>
			  `;
	}
}
