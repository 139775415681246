//recurse through error opj and extract all "message" properties
export function errorMessages(errors) {
	const messages = [];
	const recurse = (obj) => {
		if (obj && typeof obj === 'object') {
			if (obj.message) {
				messages.push(obj.message);
			} else {
				Object.values(obj).forEach((v) => recurse(v));
			}
		}
	};
	recurse(errors);
	return messages;
}

export function hasErrors(errors) {
	return errorMessages(errors).length > 0;
}
