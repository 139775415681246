import superagent from 'superagent';
import superagentUse from 'superagent-use';
import csrf from '../agent/csrf';
import sid from '../agent/sid';

const agent = superagentUse(superagent);
agent.use(csrf());
agent.use(sid());

export default agent;
