import {connect} from 'pwa-helpers';
import {LitElement, html} from 'lit';
import {navigator, router} from 'lit-element-router';
import {customElement} from 'lit/decorators/custom-element.js';
import {msg} from '@lit/localize';
import store from '../../store';
import style from '../../../../scss/artworks.scss';
import {
	createArtwork
} from '../../slices/artworkSlice';
import {selectProfileDefaultLanguage, selectCurrentProfileId, selectLanguage} from '../../selectors';
import arrowRight from '../../icons/arrowRight';
import formPageStyle from '../../../../scss/formPage.scss';

@customElement('arc-artworks')
export class Artworks extends connect(store)(navigator(LitElement)) {
	static styles = [formPageStyle, style];

	static properties = {
		materials: {type: Array},
	};

	constructor() {
		super();
		this.currentLanguage = 'en';
		this.artworks = [];
		store.subscribe(() => this.requestUpdate());
	}

	stateChanged(state) {
		this.state = state;
		this.status = state.status;
		this.lang = selectLanguage(state);

		this.currentLanguage = selectProfileDefaultLanguage(state);

		// TODO: get currently selected profileId from status state
		this.profileId = selectCurrentProfileId(state);
	}

	connectedCallback() {
		super.connectedCallback();
	}

	disconnectedCallback() {
		super.disconnectedCallback();
	}

	handleAddArtwork() {
		store.dispatch(createArtwork({profileId: this.profileId}));
	}

	render() {
		const state = store.getState();

		// console.log('artworksList', this.materials);
		return html`
			<div class="formPage artworks">
				<arc-toolbar class="toolbar">
					<div class="leftSlot" slot="left">
						<arc-breadcrumbs>
							<arc-routerlink route="artworks">${msg('Artworks')}</arc-routerlink>
						</arc-breadcrumbs>
					</div>
					<div class="rightSlot" slot="right">
						<arc-button
							title="${msg('Add Artwork')}"
							type="primary"
							.additionalInfo="${false}"
							class="saveProfileButton"
							@click=${this.handleAddArtwork}
						></arc-button>
					</div>
				</arc-toolbar>

				<header>
					<h2 class="pageTitle">${msg('Artworks')}</h2>
					<arc-content-language-switch></arc-content-language-switch>
				</header>

				<arc-artwork-list class=artworkList></arc-artwork-list>
			</div>
		`;
	}
}
