import {LitElement, html, css} from 'lit';
import {customElement} from 'lit/decorators.js';
import {connect} from 'pwa-helpers';

import store from '../store';
import style from '../../../scss/toastBanner.scss';
import {hideMessage} from '../slices/statusSlice';

@customElement('arc-toast-banner')
export class ToastBanner extends connect(store)(LitElement) {
	static styles = [style];

	static properties = {
		message: {type: String},
		messageType: {type: String},
		showMessage: {type: Boolean}
	};

	timeout = null;

	constructor() {
		super();
		this.message = '';
		this.messageType = '';
		this.showMessage = false;
	}

	stateChanged(state) {
		this.message = state.status.message;
		this.messageType = state.status.messageType;
		this.showMessage = state.status.showMessage;

		if (this.showMessage) {
			// hide after 5 seconds?
			clearTimeout(this.timeout);
			this.timeout = setTimeout(() => {
				store.dispatch(hideMessage());
			}, 5000);
		}
	}

	connectedCallback() {
		super.connectedCallback();
		window.addEventListener('scroll', this.handleScroll.bind(this));
	}

	disconnectedCallback() {
		super.disconnectedCallback();
		window.removeEventListener('scroll', this.handleScroll.bind(this));
	}

	handleScroll() {
		const scrollPosition = window.scrollY;

		if (scrollPosition > 0) {
			this.style.top = '0';
		} else {
			this.style.top = '72px';
		}
	}

	render() {
		return html`
			<div class="toastMessage ${this.messageType} ${this.showMessage ? 'active' : ''}">${this.message}</div> `;
	}
}
