import {selectGlobalPermissions, selectProfilePermissions} from '../selectors';

export function isPermitted(requirements, profile, state) {
	let meets = false;
	const globalPermissions = selectGlobalPermissions(state);
	if (profile) {
		const allProfilePermissions = selectProfilePermissions(state);
		const profilePermissions = allProfilePermissions[profile];
		meets = requirements.some(requirement => profilePermissions?.includes(requirement));
	} else {
		meets = requirements.some(requirement => globalPermissions?.includes(requirement));
	}
	return meets;
}
