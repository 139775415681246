import {LitElement, html, css} from 'lit';
import {customElement} from 'lit/decorators.js';
import {connect} from 'pwa-helpers';
import style from '../../../../scss/collapsibleSection.scss';
import {selectGlobalPermissions, selectProfilePermissions} from '../../selectors';
import store from '../../store';
import {isPermitted} from '../../util/isPermitted';

@customElement('arc-is-permitted')
export class IsPermitted extends connect(store)(LitElement) {
	static styles = [style];

	static properties = {
		requirements: {type: Array}, // necessary permissions
		profile: {type: Number},
		isPermitted: {type: Boolean}
	};

	constructor() {
		super();
		this.requirements = [];
		this.isPermitted = false;
		store.subscribe(() => this.requestUpdate());
	}

	stateChanged(state) {
		this.isPermitted = isPermitted(this.requirements, this.profile, state);
	}

	connectedCallback() {
		super.connectedCallback();
	}

	render() {
		// if this.requirements is included in globalPermissions, return slot
		return this.isPermitted ? html`
				<slot></slot>
		`: null;
	}
}
