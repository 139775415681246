import {LitElement, html} from 'lit';
import {connect} from 'pwa-helpers';
import {customElement} from 'lit/decorators.js';
import {classMap} from 'lit/directives/class-map.js';
import style from '../../../../scss/tooltip.scss';
import info from '../../icons/info';
import store from '../../store';

@customElement('arc-tooltip')
export class Tooltip extends connect(store)(LitElement) {
	static styles = [style];

	static properties = {
		text: {type: String}
	};

	constructor() {
		super();
		this.text = '';
	}

	stateChanged(state) {
		this.state = state;
	}
	render() {
		return html`
			<div class="tooltipWrapper">
				<span class="infoIcon">
					${info}
				</span>
				<span class="tooltipText">${this.text}</span>
			</div>
		`;
	}
}
