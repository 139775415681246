import {LitElement, html} from 'lit';
import {customElement} from 'lit/decorators.js';
import {navigator} from 'lit-element-router';
import {msg} from '@lit/localize';
import {connect} from 'pwa-helpers';
import {when} from 'lit/directives/when.js';

import style from '../../../../scss/allowedListItem.scss';
import store from '../../store';
import {
	grantPermissions,
	revokePermissions,
	selectAllowedAccountById
} from '../../slices/accountSlice';
import {selectCurrentAccountId} from '../../selectors';

@customElement('arc-allowed-list-item')
export class AllowedListItem extends connect(store)(navigator(LitElement)) {
	static styles = [style];

	static properties = {
		accountId: {type: String},
		profileId: {type: Number},
	};

	availablePermissions = [
		'profile/view',
		'profile/edit',
		'artwork/view',
		'artwork/edit'
	]

	constructor() {
		super();
		this.isDropdownOpen = false;
		this.permissions = [];
		this.accountId = null;
		store.subscribe(() => this.requestUpdate());
	}

	stateChanged(state) {
		if (this.accountId) {
			this.account = selectAllowedAccountById(state, this.accountId);
		}
		this.currentAccount = selectCurrentAccountId(state);
	}

	connectedCallback() {
		super.connectedCallback();
	}

	toggleDropdown() {
		this.isDropdownOpen = !this.isDropdownOpen;
	}

	closeDropdown() {
		this.isDropdownOpen = false;
	}

	hasPermission(permission) {
		return this.account.permissions?.includes(permission) ? 1 : 0;
	}
	isInvitedByCurrentAccount() {
		return this.account?.invitedBy === this.currentAccount;
	}
	isOwner() {
		return this.account?.isOwner === 1;
	}

	onChangePermission(permission, value) {
		if (value=== 1) {
			store.dispatch(
				grantPermissions({
					targetAccountId: this.account.id,
					profileId: this.profileId,
					permissions: [permission]
			}));
		} else {
			store.dispatch(
				revokePermissions({
					targetAccountId: this.account.id,
					profileId: this.profileId,
					permissions: [permission]
				}));
		}
	}

	render() {
		if (!this.accountId) return html``;

		return html`
			<div class="allowedItem">
				<div class="cell">
						${this.account.name}
						${when(
							this.isOwner(),
			() => html`(${msg('You')})`
						)}
				</div>
				<div class="cell">
						${this.account.email}
				</div>
				<div class="cell">
					${this.account.hasLoggedInOnce ? msg('Yes') : msg('No')}
				</div>
				<div class="cell">
					${this.account.invitedByName}
				</div>
				<div class="cell">
					${when(this.isInvitedByCurrentAccount() && !this.isOwner(), () => html`
					<arc-checkbox
						title="${msg('View')}"
						?disabled=${true}
						?noFieldTitle=${true}
						.value=${this.hasPermission('profile/view')}
						@value-change=${(ev) => this.onChangePermission('profile/view', ev.detail.value)}>
					</arc-checkbox>
					<arc-checkbox
						title="${msg('Edit')}"
						.noFieldTitle=${true}
						?disabled=${!this.isInvitedByCurrentAccount() || this.isOwner()}
						.value=${this.hasPermission('profile/edit')}
						@value-change=${(ev) => this.onChangePermission('profile/edit', ev.detail.value)}>
					</arc-checkbox>
					`)}

				</div>
				<div class="cell">
					${when(this.isInvitedByCurrentAccount() && !this.isOwner(), () => html`
					<arc-checkbox
						title="${msg('View')}"
						?disabled=${true}
						.noFieldTitle=${true}
						.value=${this.hasPermission('artwork/view')}
						@value-change=${(ev) => this.onChangePermission('artwork/view', ev.detail.value)}>
					</arc-checkbox>
					<arc-checkbox
						title="${msg('Edit')}"
						.noFieldTitle="${true}"
						?disabled=${!this.isInvitedByCurrentAccount() || this.isOwner()}
						.value=${this.hasPermission('artwork/edit')}
						@value-change=${(ev) => this.onChangePermission('artwork/edit', ev.detail.value)}>
					</arc-checkbox>
					`)}
				</div>
			</div>
    `;
	}
}
