import {LitElement, html, css} from 'lit';
import {customElement} from 'lit/decorators.js';
import {connect} from 'pwa-helpers';
import {msg} from '@lit/localize';
import store from '../../store';
import style from '../../../../scss/confirmationDialog.scss';
import close from '../../icons/close';
import {closeDialog} from '../../slices/statusSlice';

@customElement('arc-confirmation-dialog')
export class ConfirmationDialog extends connect(store)(LitElement) {
	static styles = [style];
	static properties = {
		open: {type: Boolean},
		message: {type: String},
		warningMessage: {type: String},
		showConfirmButton: {type: Boolean},
		title: {type: String},
		labelConfirm: {type: String},
		labelCancel: {type: String},
	};

	constructor() {
		super();
		this.open = false;
		this.message = '';
		this.warningMessage = '';
		this.title = '';
		this.showConfirmButton = true;
		this.labelConfirm = null;
		this.labelCancel = null;
		store.subscribe(() => this.requestUpdate());
	}

	stateChanged(state) {
		this.message = state.status.dialog.message;
		this.warningMessage = state.status.dialog.warningMessage;
		this.title = state.status.dialog.title;
		this.labelConfirm = state.status.dialog.labelConfirm;
		this.labelCancel = state.status.dialog.labelCancel;

		this.open = state.status.dialog.open;
	}
	firstUpdated(changedProps) {
		this.dialog = this.renderRoot.querySelector('dialog');
		// listen to external commands
		document.addEventListener('open-dialog', this.openDialog.bind(this));
		document.addEventListener('close-dialog', this.closeDialog.bind(this));
		// do something when closed
		this.dialog.addEventListener('close', this.handleClose.bind(this));
	}

	openDialog() {
		this.dialog.returnValue = 'cancel';
		this.dialog.showModal();
	}
	closeDialog() {
		this.dialog.close();
	}

	handleOutsideClick(e) {
		if (e.target === this.dialog) this.dialog.close('cancel');
	}
	handleClose(e) {
		// called always when dialog is closed
		// return value contains action
		this.dispatchEvent(new CustomEvent(this.dialog.returnValue, {bubbles: true, composed: true}));
	}


	render() {
		return html`
			<dialog class="dialog" @click=${this.handleOutsideClick}>
				<div class="modal-content">
					<h2 class="dialogTitle">${this.title}</h2>
					<p class="warningMessage">${this.warningMessage}</p>
					<p class="dialogMessage">${this.message}</p>
					<div class="dialogButtons">
						<arc-button
							@click=${() => this.dialog.close('cancel')}
							type="secondary"
							title="${this.labelCancel ?? 'OK'}"
						></arc-button>
						${this.showConfirmButton
							? html`<arc-button
									@click=${() => this.dialog.close('confirm')}
									type="primary"
									title="${this.labelConfirm ?? 'OK'}"
								></arc-button>`
							: ''}
					</div>
					<button class="closeIcon" @click=${() => this.dialog.close('cancel')} aria-label="${msg('Close')}">
						${close}
					</button>
				</div>
			</dialog>
		`;
	}
}
