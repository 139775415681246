import {html, css, LitElement} from 'lit';
import {customElement} from 'lit/decorators.js';
import {connect} from 'pwa-helpers';
import {msg} from '@lit/localize';
import {when} from 'lit/directives/when.js';
import {map} from 'lit/directives/map';
import style from '../../../../scss/image.scss';
import store from '../../store';
import {selectFileById} from '../../slices/fileSlice';


@customElement('arc-image')
class Image extends connect(store)(LitElement) {

	static styles = [style];

	static properties = {
		alt: {type: String},
		file: {type: Object},
	};

	constructor() {
		super();
		this.file = null;
		this.alt = '';
		store.subscribe(() => this.requestUpdate());
	}

	stateChanged(state) {
	}

	render() {
		if (!this.file) return null;
		const srcset = this.file.thumbs.map((thumb) => thumb.url+' '+thumb.w+'w').join(', ');
		return html`<img
			src="${this.file.thumbs[0].url}"
			srcset="${srcset}"
			alt="${this.alt}" />`;
	}
}
