import {LitElement, html, css} from 'lit';
import {navigator, router} from 'lit-element-router';
import {classMap} from 'lit/directives/class-map.js';
import {customElement} from 'lit/decorators/custom-element.js';
import {connect} from 'pwa-helpers';
import deepEqual from 'deep-equal';

import {routeUrl} from '../../util/routeUrl';
import getRoutes from '../../routes';
import linkstyle from '../../../../scss/link.scss';
import store from '../../store';
import {navigateTo} from '../../slices/statusSlice';

@customElement('arc-routerlink')
export class Routerlink extends connect(store)(navigator(router(LitElement))) {
	static properties = {
		route: {type: String},
		params: {type: Object, hasChanged: (n, o) => !deepEqual(n, o)},
		newParams: {type: Object, hasChanged: (n, o) => !deepEqual(n, o)},
		query: {type: Object},
		noUnderline: {type: Boolean},
		buttonStyle: {type: Boolean},
		boldStyle: {type: Boolean},
		whiteStyle: {type: Boolean},
		mobileSmall: {type: Boolean},
		smallText: {type: Boolean},
		icon: {type: String}
	};

	static styles = [linkstyle];

	constructor() {
		super();
		this.href = '';
		this.noUnderline = false;
		this.buttonStyle = false;
		this.whiteStyle = false;
		this.boldStyle = false;
		this.mobileSmall = false;
		this.smallText = false;
		this.icon = '';
		store.subscribe(() => this.requestUpdate());
	}

	static get routes() {
		return getRoutes();
	}

	get linkClasses() {
		return {
			'no-underline': this.noUnderline,
			buttonStyle: this.buttonStyle,
			boldStyle: this.boldStyle,
			whiteStyle: this.whiteStyle,
			mobileSmall: this.mobileSmall,
			smallText: this.smallText
		};
	}

	router(route, params, query, data) {
		// merge this.params into routerParams
		this.newRoute = this.route ? this.route : route;
		this.newParams = {...params, ...this.params};
	}

	render() {
		return html`
			<a
				@click="${this.linkClick}"
				class="${classMap(this.linkClasses)}"
				href="${routeUrl(this.newRoute, this.newParams, this.query)}"
			>
				<span class="text"><slot></slot></span>
				${this.icon ? html`<span class="icon">${this.icon}</span>` : ''}
			</a>
		`;
	}

	linkClick(event) {
		event.preventDefault();
		store.dispatch(navigateTo({route: this.newRoute, params: this.newParams, query: this.query}));
	}
}
