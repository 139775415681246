import {connect} from 'pwa-helpers';
import {LitElement, html} from 'lit';
import {customElement} from 'lit/decorators/custom-element.js';
import {classMap} from 'lit/directives/class-map.js';
import {when} from 'lit/directives/when.js';
import {msg} from '@lit/localize';
import store from '../../store';
import style from '../../../../scss/artistCvItem.scss';
import {selectCurrentContentLanguage, selectCurrentProfileId} from '../../selectors';
import {dateToYear} from '../../util/yearDateTransform';
import {getValueForLanguage} from '../../util/getValueForLanguage';

@customElement('arc-artist-cv-item')
export class ArtistCvItem extends connect(store)(LitElement) {
	static styles = [style];

	static properties = {
		entry: {type: Object}
	};

	constructor() {
		super();
		store.subscribe(() => this.requestUpdate());
	}

	stateChanged(state) {
		this.status = state.status;
		this.profileId = selectCurrentProfileId(state);
		this.contentLanguage = selectCurrentContentLanguage(state);
	}

	async connectedCallback() {
		super.connectedCallback();
	}

	disconnectedCallback() {
		super.disconnectedCallback();
	}

	render() {
		const state = store.getState();
		const title = getValueForLanguage(this.entry.title, this.contentLanguage, true);
		return html`
			<div class="cvItem">

				<div class="date">
					${when(
						this.entry.dateFrom,
						() => html`
							${dateToYear(this.entry.dateFrom)}
							${this.entry.dateTo ? ' - ' + dateToYear(this.entry.dateTo) : ''}
						`)}
				</div>
				<div class="title ${classMap({error: title === ''})}">
					${title !== '' ? title : '[' + msg('enter title') + ']'}
					${this.entry.location || this.entry.city ? ' - ' : ''}
					${this.entry.location ? this.entry.location + ', ' : ''} ${this.entry.city ? this.entry.city : ''}
				</div>
				<div class="description">
					${when(
						this.entry.description, () => html`
							${getValueForLanguage(
								this.entry.description,
								this.contentLanguage,
								true
							)}
						`)}
				</div>

			</div>
		`;
	}
}
