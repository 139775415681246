import {connect} from 'pwa-helpers';
import {LitElement, html} from 'lit';
import {customElement} from 'lit/decorators/custom-element.js';
import {msg} from '@lit/localize';
import {when} from 'lit/directives/when.js';
import {router, navigator} from 'lit-element-router';

import store from '../../store';
import style from '../../../../scss/login.scss';
import {authenticateWithToken} from '../../slices/accountSlice';
import getRoutes from '../../routes';
import {navigateTo} from '../../slices/statusSlice';

@customElement('arc-login')
export class Login extends connect(store)(router(navigator(LitElement))) {
	static styles = [style];

	static properties = {
		loginToken: {type: String},
		loginEmail: {type: String},
		consent: {type: Boolean},
		loginSession: {type: Boolean}
	};

	devLoginToken = null;

	constructor() {
		super();
		this.loginToken = '';
		this.loginEmail = '';
		this.consent = false;
		this.longSession = false;
		store.subscribe(() => this.requestUpdate());
	}

	static get routes() {
		return getRoutes();
	}
	router(route, params, query, data) {
		this.route = route;
		this.params = params;
		this.query = query;
	}

	stateChanged(state) {
		this.accountData = state.account.data.account;
		this.accountStatus = state.account.status;
		this.loggedIn = state.account.data.loggedIn;
		this.account = state.account.data.account;
		if (state.account.data.loginEmail && this.loginEmail === '') {
			this.loginEmail = state.account.data.loginEmail;
		}
		this.loggedIn = state.account.data.loggedIn;
		this.inputErrors = state.account.data.errors?.input?.params;
	}

	async connectedCallback() {
		super.connectedCallback();
	}

	disconnectedCallback() {
		super.disconnectedCallback();
	}

	// handle Inputs
	onEmailInput(input) {
		this.loginEmail = input;
	}
	onLoginTokenInput(input) {
		this.loginToken = input;
	}
	onConsent(checked) {
		this.consent = checked === 1;
	}
	onLongSession(checked) {
		this.longSession = checked;
	}
	onRequestCode() {
		store.dispatch(navigateTo({route: 'home', params: this.params}));
	}
	isLoginPossible() {
		return this.loginEmail !== '' && this.loginToken !== '' && this.consent;
	}

	onAuthenticate(input) {
		store.dispatch(
			authenticateWithToken({
				email: this.loginEmail,
				token: this.loginToken,
				consent: this.consent,
				longSession: this.longSession,
			})
		);
	}

	render(e) {
		const state = store.getState();

		return html`
			<arc-welcome>
				${when(
					this.loggedIn,
					() => html`
						<p>
							${msg('login message')}
						</p>
					`,
					() => html`
						<div class="login">
							<h1>${msg('Login')}</h1>
							<p>
								${msg('login_description')}
							</p>
							<arc-text-input
								title="${msg('Email')}"
								placeholder="${msg('Enter Email')}"
								.value=${this.loginEmail}
								.errors=${this.inputErrors?.email?.errors}
								@value-change=${(ev) => this.onEmailInput(ev.detail.value)}>
							</arc-text-input>
							<arc-text-input
								title="${msg('Token')}"
								placeholder="${msg('Enter Token')}"
								.value=${this.loginToken}
								.errors=${this.inputErrors?.loginToken?.errors}
								@value-change=${(ev) => this.onLoginTokenInput(ev.detail.value)}>
							</arc-text-input>
							<arc-checkbox
								title="${msg('Consent')}"
								.value=${this.consent}
								.errors=${this.inputErrors?.consent?.errors}
								@value-change=${(ev) => this.onConsent(ev.detail.value)}>
							</arc-checkbox>
							<arc-checkbox
								title="${msg('LongSession')}"
								.value=${this.longSession}
								.errors=${this.inputErrors?.longSession?.errors}
								@value-change=${(ev) => this.onLongSession(ev.detail.value)}>
							</arc-checkbox>

							<div class="buttons">
								<arc-button
									@click=${this.onAuthenticate}
									.disabled=${!this.isLoginPossible()}
									title="${msg('Log In')}"
									type="primary"
									.additionalInfo="${false}">
								</arc-button>
								<arc-button
									@click=${this.onRequestCode}
									title="${msg('Request code again')}"
									type="secondary"
									.additionalInfo="${false}">
								</arc-button>
							</div>
						</div>
					`
				)}
			</arc-welcome>
		`;
	}
}
