import {html, LitElement} from 'lit';
import {customElement} from 'lit/decorators/custom-element.js';
import {when} from 'lit/directives/when.js';
import {connect} from 'pwa-helpers';
import {map} from 'lit/directives/map';
import {msg} from '@lit/localize';
import store from '../store';
import {errorMessages} from '../util/errorMessages';

import style from '../../../scss/errors.scss';

@customElement('arc-errors')
export class Errors extends connect(store)(LitElement) {
	static properties = {
		errors: {type: Array},
		messages: {type: Array},
		translatedMessages: {type: Object}
	};

	static styles = [style];

	constructor() {
		super();
		this.errors = [];
		this.messages = [];
		store.subscribe(() => this.requestUpdate());
	}

	stateChanged(state) {
		this.messages = this.errors ? errorMessages(this.errors) : [];
	}

	connectedCallback() {
		super.connectedCallback();
	}

	disconnectedCallback() {
		super.disconnectedCallback();
	}

	render() {
		// map id to translated message
		// this is done to allow extraction of the messages wie locale-extract
		const translatedMessages = {
			'error/invalidCsrfToken': msg('error/invalidCsrfToken'),
			'error/invalidInput': msg('error/invalidInput'),
			'error/required': msg('error/required'),
			'error/outOfSet': msg('error/outOfSet'),
			'error/invalidFormat': msg('error/invalidFormat'),
			'error/notArray': msg('error/notArray'),
			'error/notBool': msg('error/notBool'),
			'error/notFloat': msg('error/notFloat'),
			'error/notInt': msg('error/notInt'),
			'error/notNumber': msg('error/notNumber'),
			'error/notString': msg('error/notString'),
			'error/notIntBool': msg('error/notIntBool'),
			'error/trueRequired': msg('error/trueRequired'),
			'error/falseRequired': msg('error/falseRequired'),
			'error/oneRequired': msg('error/oneRequired'),
			'error/zeroRequired': msg('error/zeroRequired'),
			'error/invalidEmail': msg('error/invalidEmail'),
			'error/invalidUrl': msg('error/invalidUrl'),
			'error/invalidId': msg('error/invalidId'),
			'error/invalidLang': msg('error/invalidLang'),
			'error/invalidDate': msg('error/invalidDate'),
			'error/notPosInt': msg('error/notPosInt'),
			'error/notZeroPosInt': msg('error/notZeroPosInt'),
			'error/max': msg('error/max'),
			'error/maxLen': msg('error/maxLen'),
			'error/minLen': msg('error/minLen'),
			'error/uploadTooBig': msg('error/uploadTooBig'),
			'error/uploadTooLong': msg('error/uploadTooLong'),
			'error/uploadPartial': msg('error/uploadPartial'),
			'error/uploadMissingFile': msg('error/uploadMissingFile'),
			'error/uploadMissingTmpDir': msg('error/uploadMissingTmpDir'),
			'error/uploadUnableToWrite': msg('error/uploadUnableToWrite'),
			'error/uploadExtensionFailure': msg('error/uploadExtensionFailure'),
			'error/uploadGenericError': msg('error/uploadGenericError'),
			'error/uploadCategoryNotAllowed': msg('error/uploadCategoryNotAllowed'),
			'error/uploadTypeNotAllowed': msg('error/uploadTypeNotAllowed'),
			'error/maxMediaAmount': msg('error/maxMediaAmount'),
			'error/maxVideoAmount': msg('error/maxVideoAmount'),
		};
		return html`
			${when(
				this.messages.length > 0,
				() => html`
					<ul class="errors">
						${map(
							this.messages,
							(message) => html` <li class="error">${translatedMessages[message]}</li>`
						)}
					</ul>
				`
			)}
		`;
	}
}
