// return string matching the given language from object
// when useFallback is true, return the first value found in object if the given language is not found
// value: [{lang: 'en', value: ''}, {lang: 'de', value: ''}]

export function getValueForLanguage(value, language, useFallback) {
	if (!value) return '';
	let returnValue = value.find((v) => v.lang === language);
	if (!returnValue && useFallback) {
		returnValue = value.find((v) => v.value !== '');
	}
	// always return value as string or empty string when not found
	return returnValue ? returnValue.value + '' : '';
}
